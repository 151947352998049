import { Theme } from '@mui/material';
import {
  resolveLanguageIcon,
  resolveManagerIcon,
  resolveRepoIcon,
} from 'common-icons';
import { formatDistanceToNow } from 'date-fns';
import { FC } from 'react';
import { AiOutlineFork } from 'react-icons/ai';
import { BiGitRepoForked } from 'react-icons/bi';
import { FaDownload, FaStar, FaUserFriends } from 'react-icons/fa';
import { IconCircleDot, IconLicense } from '@tabler/icons-react';
import { makeStyles } from 'tss-react/mui';
import GeneralInfoSection from '../../../applications/components/ApplicationCard/GeneralInfoSection';
import { SbomLibraryItem } from '../../sbom-types';
import { IconType } from 'ox-common-types/src/icon-types';
import SbomReferences from './SbomReferences';
import { useOxFlags } from '../../../feature-flags/actions/feature-flags-actions';

const SbomInfoTab: FC<React.PropsWithChildren<SbomInfoTabProps>> = ({
  sbom,
  navigateToDependencyGraph,
}) => {
  const {
    license,
    libraryName,
    pkgName,
    latestVersionDate,
    latestVersion,
    libraryVersion,

    copyWriteInfo,
    licenseLink,
    contributors,
    downloads,
    maintainers,
    source,
    sourceLink,
    packageManager,
    packageManagerLink,
    stars,
    forks,
    openIssues,
    appName,
    appId,
    language,
    sha,
    location,
    locationLink,
    dependencyLevel,
    dependencyType,
  } = sbom;
  const { sbomMultipleReferencesEnabled } = useOxFlags();
  const SourceIcon = resolveRepoIcon(source);
  const PackageManagerIcon = resolveManagerIcon(packageManager);
  const LanguageIcon = resolveLanguageIcon(language?.toLowerCase());
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.item1}>
        <GeneralInfoSection title='Package Name' value={pkgName} />
        <GeneralInfoSection title='Library Name' value={libraryName} />
        <GeneralInfoSection title='Library Version' value={libraryVersion} />
        {!sbomMultipleReferencesEnabled && dependencyType ? (
          <GeneralInfoSection
            title='Dependency Type'
            value={`${dependencyType}${
              dependencyLevel ? `, ${dependencyLevel} level` : ''
            } `}
          />
        ) : null}
        {!sbomMultipleReferencesEnabled && location && location !== 'N/A' ? (
          <GeneralInfoSection
            title='Location'
            value={location}
            link={locationLink}
          />
        ) : null}
        <GeneralInfoSection title='SHA' value={sha || 'N/A'} />
        {latestVersion ? (
          <GeneralInfoSection title='Latest Version' value={latestVersion} />
        ) : null}
        {latestVersionDate ? (
          <GeneralInfoSection
            title='Release Date'
            value={formatDistanceToNow(new Date(latestVersionDate), {
              addSuffix: true,
            })}
          />
        ) : null}

        <GeneralInfoSection
          title='Application'
          valueIcon={SourceIcon!}
          value={appName}
          link={`/applications?appId=${appId}`}
        />
      </div>
      <div className={classes.item2}>
        {packageManager ? (
          <GeneralInfoSection
            title='Manager'
            value={packageManager}
            link={packageManagerLink}
            valueIcon={PackageManagerIcon!}
          />
        ) : null}
        {language ? (
          <GeneralInfoSection
            title='Language'
            value={language}
            valueIcon={LanguageIcon!}
          />
        ) : null}
        {source ? (
          <GeneralInfoSection
            title='Source'
            value={source}
            link={sourceLink}
            valueIcon={SourceIcon!}
            icon={BiGitRepoForked}
          />
        ) : null}

        {downloads ? (
          <GeneralInfoSection
            title='Downloads'
            value={downloads}
            icon={FaDownload}
          />
        ) : null}
        {stars ? (
          <GeneralInfoSection title='Stars' value={stars} icon={FaStar} />
        ) : null}
        {forks ? (
          <GeneralInfoSection
            title='Forks'
            value={forks}
            icon={AiOutlineFork}
          />
        ) : (
          <></>
        )}
        {maintainers ? (
          <GeneralInfoSection
            title='Maintainers'
            value={maintainers}
            icon={FaUserFriends}
          />
        ) : null}
        {contributors ? (
          <GeneralInfoSection
            title='Contributors'
            value={contributors}
            icon={FaUserFriends}
          />
        ) : null}
        {openIssues ? (
          <GeneralInfoSection
            title='Open Issues'
            value={openIssues}
            icon={IconCircleDot as IconType}
          />
        ) : null}

        <GeneralInfoSection
          title='Copyright'
          value={copyWriteInfo || 'Not Found'}
          link={licenseLink}
        />
        <GeneralInfoSection
          title='License'
          value={license || 'Not Found'}
          link={licenseLink}
          icon={IconLicense as IconType}
        />
      </div>
      {sbomMultipleReferencesEnabled && sbom.references?.length && (
        <div className={classes.item3}>
          <SbomReferences
            references={sbom.references}
            navigateToDependencyGraph={navigateToDependencyGraph}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: '100%',
  },
  item1: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    flex: 1,
    rowGap: theme.spacing(1),
    height: '100%',
    columnGap: theme.spacing(2),
    paddingRight: theme.spacing(1),
    alignItems: 'start',
    alignContent: 'start',
  },
  item2: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    borderLeft: `1px solid ${theme.palette.divider}`,
    flex: 1,
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(2),
    padding: theme.spacing(0, 1),
    alignItems: 'start',
    alignContent: 'start',
  },
  item3: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderLeft: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1),
    overflowY: 'auto',
    minWidth: 300,
  },
}));

export interface SbomInfoTabProps {
  sbom: SbomLibraryItem;
  navigateToDependencyGraph: (trigger: string) => void;
}

export default SbomInfoTab;
