import { createAsyncState } from 'async-utils';
import { Nullable, Order } from 'ox-common-types';
import { createStore } from 'store-utils';
import {
  DependencyEdge,
  DependencyNode,
} from '../../issues/common/types/issues-types';
import { SbomLibraryItem } from '../sbom-types';

export const defaultOrderBy = {
  field: 'LibraryName',
  direction: Order.Desc,
};

export const initialSbomStoreValues = {
  topSearchValue: '',
  orderField: defaultOrderBy.field,
  orderDirection: defaultOrderBy.direction,
  loading: createAsyncState(),
  libraries: null,
  total: 0,
  offset: 0,
  filtersOpen: true,
  selectedSbom: null,
  lastDrawerHeight: undefined,
  dependencyGraph: null,
  selectedSbomData: null,
  selectedTrigger: null,
  loadingDependencyGraph: false,
};

const SbomStore = createStore<SbomStoreState>(
  initialSbomStoreValues,
  'SBOM Store',
);

export interface SbomStoreState {
  loading: ReturnType<typeof createAsyncState>;
  orderField: string;
  orderDirection: Order;
  libraries: Nullable<SbomLibraryItem[]>;
  total: number;
  topSearchValue: string;
  offset: number;
  filtersOpen: boolean;
  lastDrawerHeight?: number;
  selectedSbom: Nullable<string>;
  dependencyGraph: Nullable<{
    nodes: DependencyNode[];
    edges: DependencyEdge[];
    allNodes: DependencyNode[];
    allEdges: DependencyEdge[];
  }>;
  selectedSbomData: Nullable<SbomLibraryItem>;
  selectedTrigger: Nullable<string>;
  loadingDependencyGraph: boolean;
}

export default SbomStore;
