import { useSnapshot } from 'valtio';
import IssuesStore from '../../active-issues/stores/issues-store';
import { getIssueActionsConfig } from '../config/issue-actions-config';
import AggsUtilityStore from '../stores/aggs-utility-store';
import { IssueAction, IssuePages } from '../types/issues-types';

const useIssuesActions = (options?: {
  isResolvedIssues: boolean;
}): IssueAction[][] => {
  const { selectedIssue } = useSnapshot(IssuesStore);
  const { selectedAggs } = useSnapshot(AggsUtilityStore);
  const isPRAvailable = Boolean(selectedIssue?.isPRAvailable);

  const groupedActions = getIssueActionsConfig({
    exclusionsCategories: selectedIssue?.exclusions,
    isPRAvailable,
    policyId: selectedIssue?.policy.id,
    categoryId: selectedIssue?.category.categoryId,
    hasComment: !!selectedIssue?.comment,
    isFixAvailable: !!selectedIssue?.fixes,
    isGptAvailable: !!selectedIssue?.isGPTFixAvailable,
    showJiraUnlinkTicketSubMenuItem: selectedIssue?.tickets?.length !== 0,
    showUnlinkTicket: selectedIssue?.tickets?.length !== 0,
    isFalsePositive: selectedIssue?.isFalsePositive,
    selectedAggs,
    issueId: selectedIssue?.issueId || '',
    issuesPage: !!options?.isResolvedIssues
      ? IssuePages.ResolvedIssues
      : IssuePages.CurrentIssues,
  });

  return groupedActions;
};

export default useIssuesActions;
