import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  CredentialsConfigureResponse,
  RemoveCredentialsInput,
} from '../../connectors-types';
import mutation from './remove-credentials.gql';
import mutationV2 from './remove-credentials-v2.gql';

const removeCredentials = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      removeCredentialsInput: RemoveCredentialsInput,
      isV2: boolean = false,
    ): Promise<CredentialsConfigureResponse> => {
      try {
        const results = await client.mutate<RemoveCredentialsResponse>({
          mutation: isV2 ? mutationV2 : mutation,
          variables: { removeCredentialsInput },
        });

        if (!results.data) {
          throw new Error('Failed to remove credentials');
        }

        return results.data.removeCredentials.connector;
      } catch (error) {
        logger.error(`Removing credentials failed due to an error: ${error}`);
        logger.error(error);
        throw error;
      }
    },
  };
};

interface RemoveCredentialsResponse {
  removeCredentials: { connector: CredentialsConfigureResponse };
}

export default removeCredentials;
