import { ActionType } from '@oxappsec/policy-workflow-service';
import create from 'zustand';
import { TriggerCategory } from '../../services/get-triggers/get-triggers';
import { Condition } from '../../services/get-conditions/get-conditions';
import { ActionCategory } from '../../services/get-actions/get-actions';

type RFState = {
  actionsConnectionMap: Partial<{ [type in ActionType]: boolean }>;
  policyConnectionsMap: { [key: string]: boolean };
  triggers: TriggerCategory[];
  conditions: Condition[];
  actions: ActionCategory[];
};

const useWorkflowsResourcesStore = create<RFState>((set, get) => ({
  actionsConnectionMap: {},
  policyConnectionsMap: {},
  triggers: [],
  conditions: [],
  actions: [],
}));

export default useWorkflowsResourcesStore;
