import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  DependencyEdge,
  DependencyNode,
} from '../../../issues/common/types/issues-types';
import query from './get-dependency-graph.gql';

const getDependencyGraph = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getSbomDependenciesGraph: GetDependencyGraphInput,
      cache = true,
    ) => {
      try {
        const results = await client.query<{
          getSbomDependenciesGraph: SbomDependencyGraphResponse;
        }>({
          query,
          variables: { getSbomDependenciesGraph },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });

        return results.data.getSbomDependenciesGraph;
      } catch (error) {
        logger.error('Failed to fetch sbom libraries', error);
        return null;
      }
    },
  };
};

export interface GetDependencyGraphInput {
  appId: string;
  triggerPackage: string;
  fileName?: string;
  library?: string;
  scanId?: string;
}

export interface SbomDependencyGraphResponse {
  nodes: DependencyNode[];
  edges: DependencyEdge[];
  allNodes: DependencyNode[];
  allEdges: DependencyEdge[];
}

export default getDependencyGraph;
