import {
  Box,
  ClickAwayListener,
  Divider,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { FilterTypeToFilter } from 'ox-filter-utils';
import React, { FC, useState } from 'react';
import { FaDoorOpen } from 'react-icons/fa';
import { GiMeteorImpact } from 'react-icons/gi';
import { ImTarget } from 'react-icons/im';
import { FaInfoCircle } from 'react-icons/fa';
import { makeStyles } from 'tss-react/mui';
import {
  SeverityByCategory,
  SeverityChangeFactorTypes,
} from '../../types/issues-types';
import ActionsOptions from '../ActionsOptions/ActionsOptions';
import SeverityTrendIcon from '../SeverityTrendIcon';
import SeverityEvidence from 'ox-react-components/src/SeverityEvidence/SeverityEvidence';

const SeverityChangeFactor: FC<IssueCardSeverityProps> = ({
  severitiesByType,
  filters,
  isEnableAdvancedOptionsToolTip,
}) => {
  const { classes } = useStyles();
  const [openTooltipList, setOpenTooltipList] = useState<string[]>([]);

  const handleAddToOpenTooltipList = (index: string) => {
    const newState = [...openTooltipList, index];
    setOpenTooltipList(newState);
  };

  const handleRemoveFromOpenTooltipList = (index: string) => {
    const newState = openTooltipList.filter(i => i !== index);
    setOpenTooltipList(newState);
  };

  return (
    <div className={classes.mappingSeverity}>
      {Object.entries(severitiesByType).map((severityChange, index) => {
        return (
          <React.Fragment key={index}>
            <Divider orientation='vertical' flexItem />
            <div className={classes.elementBg}>
              <Typography
                variant='body1'
                color='text.secondary'
                fontWeight='bold'
                whiteSpace={'pre-line'}
                className={classes.title}>
                {severityChange[0] ===
                SeverityChangeFactorTypes.Reachability ? (
                  <FaDoorOpen className={classes.icon} />
                ) : severityChange[0] ===
                  SeverityChangeFactorTypes.Exportability ? (
                  <ImTarget className={classes.icon} />
                ) : (
                  <GiMeteorImpact className={classes.icon} />
                )}
                {severityChange &&
                  severityChange.length > 0 &&
                  severityChange[0]}
              </Typography>
              {severityChange &&
                severityChange.length > 0 &&
                severityChange[1].map((item, index) => {
                  const severityChangeIndex = severityChange[0] + index;
                  return (
                    <div key={index}>
                      {item.description ? (
                        <ClickAwayListener
                          onClickAway={() =>
                            handleRemoveFromOpenTooltipList(severityChangeIndex)
                          }>
                          <Tooltip
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, -10],
                                    },
                                  },
                                ],
                              },
                            }}
                            open={openTooltipList.includes(severityChangeIndex)}
                            onClose={() =>
                              handleRemoveFromOpenTooltipList(
                                severityChangeIndex,
                              )
                            }
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            arrow
                            placement='top'
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  maxWidth: window.innerWidth,
                                },
                              },
                            }}
                            title={
                              item.extraInfo && item.extraInfo.length > 0 ? (
                                <div className={classes.container}>
                                  <Typography
                                    variant='body2'
                                    color='text.primary'
                                    whiteSpace={'pre-line'}
                                    mb={0.5}>
                                    {item.description}
                                  </Typography>
                                  <Typography
                                    variant='body2'
                                    color='text.secondary'
                                    mb={0.5}>
                                    {item.evidenceLabel
                                      ? item.evidenceLabel
                                      : 'Found In:'}
                                  </Typography>
                                  {item.extraInfo.map((extra, index) => (
                                    <SeverityEvidence
                                      key={index}
                                      evidence={extra}
                                      index={index}
                                      withoutAutoNumbering={
                                        item.withoutAutoNumbering
                                      }
                                    />
                                  ))}
                                  {isEnableAdvancedOptionsToolTip && (
                                    <ActionsOptions
                                      value={item.name}
                                      filterType={
                                        FilterTypeToFilter.SeverityChangeReasons
                                      }
                                      filters={
                                        filters[
                                          FilterTypeToFilter
                                            .SeverityChangeReasons
                                        ]
                                      }
                                      withFilter
                                    />
                                  )}
                                </div>
                              ) : isEnableAdvancedOptionsToolTip ? (
                                <>
                                  <Typography
                                    variant='body2'
                                    color='text.primary'
                                    className={classes.text}>
                                    {item.description}
                                  </Typography>
                                  <ActionsOptions
                                    value={item.name}
                                    filterType={
                                      FilterTypeToFilter.SeverityChangeReasons
                                    }
                                    filters={
                                      filters[
                                        FilterTypeToFilter.SeverityChangeReasons
                                      ]
                                    }
                                    withFilter
                                  />
                                </>
                              ) : (
                                <Typography
                                  variant='body2'
                                  color='text.primary'
                                  className={classes.text}>
                                  {item.description}
                                </Typography>
                              )
                            }>
                            <Typography
                              variant='body1'
                              color='text.primary'
                              component='div'
                              className={classes.nameContainer}
                              onClick={() =>
                                handleAddToOpenTooltipList(severityChangeIndex)
                              }>
                              <>
                                <SeverityTrendIcon trend={item.trend} />
                              </>
                              <Box>
                                {item.name}{' '}
                                <span>
                                  <FaInfoCircle size={12} opacity={0.2} />
                                </span>
                              </Box>
                            </Typography>
                          </Tooltip>
                        </ClickAwayListener>
                      ) : (
                        <Typography
                          variant='body1'
                          color='text.primary'
                          component='div'
                          className={classes.nameContainer}>
                          <>
                            <SeverityTrendIcon trend={item.trend} />
                          </>
                          {item.name}
                        </Typography>
                      )}
                    </div>
                  );
                })}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'left',
    alignItems: 'center',
    marginBlockEnd: theme.spacing(0.5),
  },
  section: {
    marginBlockStart: theme.spacing(0.8),
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    ':hover': {
      cursor: 'pointer',
    },
  },
  trendIcon: {
    width: 15,
  },
  mappingSeverity: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  elementBg: {
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    paddingBlock: theme.spacing(1),
    gap: theme.spacing(0.5),
  },
  icon: {
    color: theme.palette.primary.main,
  },
  container: {
    overflowY: 'auto',
    maxHeight: window.innerHeight / 2.5,
    maxWidth: 800,
  },
  text: {
    maxWidth: 260,
  },
}));

export interface IssueCardSeverityProps {
  severitiesByType: SeverityByCategory;
  filters: {};
  isEnableAdvancedOptionsToolTip?: boolean;
}

export default SeverityChangeFactor;
