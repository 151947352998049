import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  ApolloClientCache,
  KeyStringGenericObject,
  Nullable,
} from 'ox-common-types';
import query from './get-pbom-breakdown.gql';

const getPbomBreakdown = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getPbomBreakdownInput: {
        [key: string]: {
          owners: string[];
          tagIds: string[];
        };
      },
      cache = true,
    ): Promise<Nullable<GetPbomBreakdownResponse>> => {
      try {
        const results = await client.query<GetPbomBreakdownResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: getPbomBreakdownInput,
        });
        if (!results.data) return null;
        return results.data;
      } catch (e) {
        logger.error('Failed to fetch PBOM breakdown, ', e);
        return null;
      }
    },
  };
};

interface GetPbomBreakdownResponse {
  getPbomBreakdown: KeyStringGenericObject<{ total: number }>;
}

export default getPbomBreakdown;
