import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import query from './get-input-fields.gql';
import { TicketFieldConfig } from '../../types/ticketing-types';
import { IssuePages } from '../../../issues/common/types/issues-types';

const getInputFields = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (getTicketFieldsInput: GetTicketFieldsInput) => {
      try {
        const results = await client.query<GetInputFieldsResponse>({
          query,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { getTicketFieldsInput },
        });
        return results.data.getTicketFields.fields;
      } catch (error) {
        logger.error('Failed to get input fields', error);
        return null;
      }
    },
  };
};

export interface GetTicketFieldsInput {
  ticketingVendor: string;
  issueId: string;
  isBulkAction: boolean;
  issueType: IssuePages;
  aggItemsIds: string[];
}

export interface GetInputFieldsResponse {
  getTicketFields: {
    fields: TicketFieldConfig[];
  };
}
export default getInputFields;
