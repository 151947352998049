import { useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';
import SbomInfoTab from '../../../../../../../sbom/components/SbomInfoTab/SbomInfoTab';
import { Nullable } from '../../../../../../../../../../utilities/ox-common-types/src/typescript-types';
import { SbomLibraryItem } from '../../../../../../../sbom/sbom-types';
import issuesService from '../../../../../services';
import getIssueSbomDataQuery from '../../get-issue-sbom-data.gql';
import { loadDependencyGraph } from '../DependencyGraphTab/dependency-graph-tab-actions';

const SBOMInfoTabContainer: React.FC<IssueDrawerTabProps> = ({
  setIsLoading,
  setIsError,
  moveToTab,
}) => {
  const { selectedIssue } = useSnapshot(IssuesStore);
  const [sbomInfoData, setSbomInfoData] =
    useState<Nullable<SbomLibraryItem>>(null);

  const getIssueSbomData = useCallback(async () => {
    if (selectedIssue) {
      setIsLoading(true);
      const result = await issuesService.getIssueDetailsByQuery.execute({
        issueId: selectedIssue.issueId,
        query: getIssueSbomDataQuery,
      });
      setIsLoading(false);
      if (result?.sbom) {
        result?.sbom && setSbomInfoData(result?.sbom);
      } else {
        setIsError(true);
      }
    }
  }, [selectedIssue, setIsError, setIsLoading]);

  useEffect(() => {
    getIssueSbomData();
  }, [getIssueSbomData]);

  useEffect(() => {
    return () => {
      setIsError(false);
      setIsLoading(false);
    };
  }, [setIsError, setIsLoading]);

  return sbomInfoData ? (
    <SbomInfoTab
      sbom={sbomInfoData}
      navigateToDependencyGraph={trigger => {
        loadDependencyGraph(
          sbomInfoData.appId,
          trigger,
          `${sbomInfoData.libraryName}@${sbomInfoData.libraryVersion}`,
        );
        moveToTab && moveToTab('Dependency Graph');
      }}
    />
  ) : null;
};

export default SBOMInfoTabContainer;
