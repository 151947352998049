import { createTheme, Grow, Theme, Zoom } from '@mui/material';
import { snapshot } from 'valtio';
import ThemeStore from './theme-store';

type EnhancedWindow = Window & { safari: string | undefined };
const w = window as unknown as EnhancedWindow;

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      paper: '#FFFFFF',
      default: '#EFF0F5',
    },
    dashInventory: {
      textColor: {
        colorRelevant: '#37375C',
        colorAppsText: '#7179FE',
      },
    },
    primary: {
      main: '#6837FF',
      light: '#B39BFF',
    },
    secondary: {
      main: '#A7ACFF',
    },
    text: {
      primary: '#37375C',
      secondary: '#9B9BAD',
      link: '#5059FF',
      disabled: '#a6a6a6',
    },
    info: {
      main: '#FFA302',
    },
    success: {
      main: '#33C600',
      disabled: '#C7EEB7',
    },
    warning: {
      main: '#FA8D83',
    },
    error: {
      main: '#FA1414',
      disabled: '#FFB2B2',
    },
    grey: {
      '100': '#E5E5E5',
    },
    progress: {
      backgroundColor: '#37375C',
      barColor: 'white',
      trackColor: '#696985',
      textColor: 'white',
    },
    badge: {
      connected: '#33C600',
      notConnected: '#FFA302',
      connectionIssue: '#FA1414',
      comingSoon: '#F1F2F1',
    },
    panel: {
      panelHeader: {
        backgroundColor: 'rgba(239, 240, 245, 0.5)',
      },
    },
    coverageColors: {
      clientCoverage: '#A7ACFF',
      oxCoverage: '#6837FF',
      noCoverage: '#FA8D83',
      notApplicable: '#E5E5E5',
    },
    categoryRiskColor: {
      appox: '#8B30AB',
      appoxalypse: '#8B30AB',
      critical: '#843131',
      high: '#D01502',
      medium: '#FFC051',
      low: '#5C94ED',
      info: '#848484',
      passed: '#33C600',
      none: '#E5E5E5',
      noIssues: '#a9d295',
    },
    statButton: {
      stat: {
        border: '#ffffff40',
      },
      active: {
        background: '#EFF0F5',
        border: '#A7ACFF',
      },
      disabled: {
        background: 'rgba(255, 255, 255, 0.1)',
      },
      hover: {
        background:
          'linear-gradient(0deg, rgba(80, 89, 255, 0.05), rgba(80, 89, 255, 0.05)), #FFFFFF;',
      },
      increase: {
        main: '#37375C',
      },
      decrease: {
        main: '#37375C',
      },
    },
    workflow: {
      nodes: {
        Action: '#5C94ED',
        Condition: '#FFC051',
        Trigger: '#33C600',
        Pipeline: '#6837FF',
      },
    },
    onboarding: {
      codeRepoButton: {
        background: '#FFFFFF',
        text: '#37375C',
        selected:
          'linear-gradient(0deg, rgba(80, 89, 255, 0.1), rgba(80, 89, 255, 0.1)), #FFFFFF',
      },
      oxLogo: {
        color: '#1D164D',
      },
    },
    gptContent: {
      fontFamily:
        'Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
      answerBgColor: '#f7f7f8',
      questionBgColor: '#ffffff',
      fontColor: 'rgb(55, 65, 81)',
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h1: {
      fontSize: '1.125rem',
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        TransitionComponent:
          (w.safari as string | undefined) !== undefined ? Zoom : Grow,
      },
      styleOverrides: {
        arrow: {
          '&:before': {
            backgroundColor: 'white',
            borderRadius: '0 0 3px 0',
          },
        },
        tooltip: {
          // maxWidth: 600,
          color: 'inherit',
          filter: 'drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.15))',
          backgroundColor: 'white',
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#0F1321',
      default: '#191D25',
    },
    dashInventory: {
      textColor: {
        colorRelevant: '#E5E5E5',
        colorAppsText: '#7D68FF',
      },
    },
    primary: {
      main: '#9A8AFE',
      light: '#B39BFF',
    },
    secondary: {
      main: '#A7ACFF',
    },
    text: {
      primary: '#E5E5E5',
      secondary: '#E5E5E5AA',
      link: '#5059FF',
      disabled: '#E5E5E5',
    },
    info: {
      main: '#FFA302',
    },
    success: {
      main: '#33C600',
      disabled: '#C7EEB7',
    },
    warning: {
      main: '#FA8D83',
    },
    error: {
      main: '#FA1414',
      disabled: '#FFB2B2',
    },
    grey: {
      '100': '#E5E5E5',
    },
    progress: {
      backgroundColor: '#37375C',
      barColor: 'white',
      trackColor: '#696985',
      textColor: 'white',
    },
    badge: {
      connected: '#33C600',
      notConnected: '#FFA302',
      connectionIssue: '#FA1414',
      comingSoon: '#F1F2F1',
    },
    panel: {
      panelHeader: {
        backgroundColor: '#1C1F2A',
      },
    },
    coverageColors: {
      clientCoverage: '#A7ACFF',
      oxCoverage: '#6837FF',
      noCoverage: '#FA8D83',
      notApplicable: '#E5E5E5',
    },
    categoryRiskColor: {
      appox: '#8B30AB',
      appoxalypse: '#8B30AB',
      critical: '#843131',
      high: '#D01502',
      medium: '#FFC051',
      low: '#5C94ED',
      info: '#848484',
      passed: '#33C600',
      none: '#E5E5E5',
      noIssues: '#a9d295',
    },
    statButton: {
      stat: {
        border: '#ffffff40',
      },
      active: {
        background: '#7D68FF',
        border: '#7179fe80',
      },
      hover: {
        background: 'rgba(255, 255, 255, 0.1)',
      },
      disabled: {
        background: 'rgba(255, 255, 255, 0.1)',
      },
      increase: {
        main: '#E5E5E5',
      },
      decrease: {
        main: '#E5E5E5',
      },
    },
    workflow: {
      nodes: {
        Action: '#5C94ED',
        Condition: '#FFC051',
        Trigger: '#33C600',
        Pipeline: '#6837FF',
      },
    },
    onboarding: {
      codeRepoButton: {
        background: '#FFFFFF',
        text: '#37375C',
        selected:
          'linear-gradient(0deg, rgba(80, 89, 255, 0.1), rgba(80, 89, 255, 0.1)), #cabaff',
      },
      oxLogo: {
        color: '#FFFFFF',
      },
    },
    gptContent: {
      fontFamily:
        'Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
      answerBgColor: '#444654',
      questionBgColor: '#343541',
      fontColor: 'rgb(209, 213, 219)',
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    fontWeightBold: 'normal',
    fontWeightRegular: 'normal',
    fontWeightLight: 'normal',
    fontWeightMedium: 'normal',
    h1: {
      fontSize: '1.125rem',
    },
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'inherit',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        TransitionComponent: w['safari'] !== undefined ? Zoom : Grow,
      },
      styleOverrides: {
        arrow: {
          '&:before': {
            backgroundColor: '#3D414E',
          },
        },
        tooltip: {
          opacity: 1,
          backgroundImage: 'inherit',
          backgroundColor: '#3D414E',
          filter: 'drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.35))',
        },
      },
    },
  },
});

// https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation
declare module '@mui/material/styles' {
  interface TypeText {
    primary: string;
    secondary: string;
    link: string;
    disabled: string;
  }
  interface Palette {
    appox: string;
    critical: string;
    onboarding: {
      codeRepoButton: {
        background: string;
        text: string;
        selected: string;
      };
      oxLogo: {
        color: string;
      };
    };
    workflow: {
      nodes: {
        Action: string;
        Condition: string;
        Trigger: string;
        Pipeline: string;
      };
    };
    gptContent: {
      fontFamily: string;
      questionBgColor: string;
      answerBgColor: string;
      fontColor: string;
    };
    statButton: {
      stat: {
        border: string;
      };
      active: {
        background: string;
        border: string;
      };
      disabled: {
        background: string;
      };
      hover: {
        background: string;
      };
      increase: {
        main: string;
      };
      decrease: {
        main: string;
      };
    };
    badge: {
      connected: string;
      notConnected: string;
      connectionIssue: string;
      comingSoon: string;
    };
    panel: {
      panelHeader: {
        backgroundColor: string;
      };
    };
    progress: {
      backgroundColor: string;
      barColor: string;
      trackColor: string;
      textColor: string;
    };
    coverageColors: {
      clientCoverage: string;
      oxCoverage: string;
      noCoverage: string;
      notApplicable: string;
    };
    categoryRiskColor: {
      [key: string]: string;
      appox: string;
      appoxalypse: string;
      critical: string;
      high: string;
      medium: string;
      low: string;
      info: string;
      passed: string;
      none: string;
      noIssues: string;
    };
    dashInventory: {
      textColor: {
        colorRelevant: string;
        colorAppsText: string;
      };
    };
    text: {
      primary: string;
      secondary: string;
      link: string;
      disabled: string;
    };
  }

  interface PaletteOptions {
    dashInventory: {
      textColor: {
        colorRelevant: string;
        colorAppsText: string;
      };
    };
    workflow: {
      nodes: {
        Action: string;
        Condition: string;
        Trigger: string;
        Pipeline: string;
      };
    };
    onboarding: {
      codeRepoButton: {
        background: string;
        text: string;
        selected: string;
      };
      oxLogo: {
        color: string;
      };
    };
    gptContent: {
      fontFamily: string;
      questionBgColor: string;
      answerBgColor: string;
      fontColor: string;
    };
    statButton: {
      stat: {
        border: string;
      };
      active: {
        background: string;
        border: string;
      };
      disabled: {
        background: string;
      };
      hover: {
        background: string;
      };
      increase: {
        main: string;
      };
      decrease: {
        main: string;
      };
    };

    badge: {
      connected: string;
      notConnected: string;
      connectionIssue: string;
      comingSoon: string;
    };
    panel: {
      panelHeader: {
        backgroundColor: string;
      };
    };
    progress: {
      backgroundColor: string;
      barColor: string;
      trackColor: string;
      textColor: string;
    };
    coverageColors: {
      clientCoverage: string;
      oxCoverage: string;
      noCoverage: string;
      notApplicable: string;
    };
    categoryRiskColor: {
      appox: string;
      appoxalypse: string;
      critical: string;
      high: string;
      medium: string;
      low: string;
      info: string;
      passed: string;
      none: string;
      noIssues: string;
    };
  }

  interface SimplePaletteColorOptions {
    disabled?: string;
  }

  interface PaletteColor {
    disabled?: string;
  }
}

export const getCurrentThemeType = (): 'light' | 'dark' => {
  const { themeType } = snapshot(ThemeStore);
  return themeType;
};

export const getCurrentTheme = (): Theme => {
  const themeType = getCurrentThemeType();
  return themeType === 'light' ? lightTheme : darkTheme;
};
