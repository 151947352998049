import { Box, ClassNameMap, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { isValid } from 'date-fns/esm';
import { logger } from 'logging-utils';
import {
  CustomLink,
  CustomTooltip,
  MultiLineEllipsis,
} from 'ox-react-components';
import { Column } from 'react-data-grid';
import { SeverityColor } from 'severity-utils';
import { ScaVulnerability } from '../../../../apps/web/src/issues/common/types/issues-types';
import { DependencyTypes } from '../../../../apps/web/src/sbom/sbom-types';
import { getDependencyIcon } from 'common-icons';
import OxSeverityCell from './OxSeverityCell';
import CveSeverityFactors from './CveSeverityFactors';
import { snapshot } from 'valtio';
import VulnerabilitiesStore from '../../../../apps/web/src/issues/common/components/IssueDrawer/IssueDrawerTabs/Tabs/IssuesCardVulnerabilitiesTab/stores/vulnerabilities-store';

const CellWithTooltip = ({
  value,
  link,
  tooltip,
}: {
  value: string;
  link?: string;
  tooltip?: string;
}) => (
  <CustomTooltip
    arrow
    placement='top'
    title={
      value ? (
        <Typography variant='body1' color='text.primary'>
          {tooltip || value}
        </Typography>
      ) : (
        ''
      )
    }>
    <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
      <MultiLineEllipsis variant='body2' lines={3}>
        {link ? <CustomLink href={link} value={value} /> : value}
      </MultiLineEllipsis>
    </Box>
  </CustomTooltip>
);

export const useVulnerabilityTable = ({
  text,
}: ClassNameMap<'text'>): Column<ScaVulnerability>[] => {
  const { data } = snapshot(VulnerabilitiesStore);

  const columns: Column<ScaVulnerability>[] = [
    {
      name: 'Vulnerability ID',
      sortable: true,
      key: 'cve',
      minWidth: 100,
      maxWidth: 150,
      formatter: ({ row }) => (
        <CellWithTooltip value={row.cve} link={row.cveLink} />
      ),
    },
    {
      name: 'Library',
      key: 'libName',
      sortable: true,
      maxWidth: 200,
      minWidth: 120,
      formatter: ({ row }) => (
        <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
          <CustomTooltip
            arrow
            placement='top'
            title={
              <Typography variant='body2' color='text.primary'>
                <b>Level: {row.chainDepth}</b> <br />
                {row.dependencyChain}
              </Typography>
            }>
            {getDependencyIcon(
              row.chainDepth === 0
                ? DependencyTypes.Direct
                : DependencyTypes.Indirect,
              'primary',
            )}
          </CustomTooltip>

          <CellWithTooltip value={`${row.libName}@${row.libVersion}`} />
        </Box>
      ),
    },
    {
      name: 'Description',
      minWidth: 200,
      key: 'description',
      formatter: ({ row }) => <CellWithTooltip value={row.description} />,
    },
    {
      name: 'Context',
      headerCellClass: text,
      width: 250,
      key: 'exploitRequirement',
      formatter: CveSeverityFactors,
    },
    {
      name: 'Discovered',
      key: 'dateDiscovered',
      minWidth: 130,
      width: 130,
      formatter: ({ row }) => {
        let date;
        try {
          date = row.dateDiscovered && new Date(row.dateDiscovered);
        } catch {
          logger.warn('Failed to parse the date', row);
        }
        return (
          <CustomTooltip arrow placement='top' title={row.dateDiscovered || ''}>
            <Typography variant='body2' color='text.primary'>
              {date && isValid(date)
                ? formatDistanceToNow(date, { addSuffix: true })
                : 'Not Available'}
            </Typography>
          </CustomTooltip>
        );
      },
    },
    {
      name: 'CVSS',
      sortable: true,
      key: 'cvsVer',
      width: 90,
      minWidth: 90,
      formatter: ({ row }) => (
        <Typography color='text.primary' variant='caption'>
          {row.cvsVer || 'Not Available'} <br />
          {row.originalSeverity && (
            <SeverityColor severity={row.originalSeverity}>
              {color => (
                <span
                  style={{
                    color,
                    fontWeight: 600,
                  }}>
                  {row.originalSeverity}
                </span>
              )}
            </SeverityColor>
          )}
        </Typography>
      ),
    },
  ];
  columns.push(
    data?.[0]?.severityFromTool
      ? {
          name: 'Tool Severity',
          sortable: true,
          key: 'severityFromTool',
          minWidth: 90,
          maxWidth: 90,
          width: 90,
          formatter: ({ row }) => (
            <Typography color='text.primary' variant='caption'>
              {row.severityNumberFromTool} <br />
              {row.severityNumberFromTool && (
                <SeverityColor severity={row.severityFromTool}>
                  {color => (
                    <span
                      style={{
                        color,
                        fontWeight: 600,
                      }}>
                      {row.severityFromTool}
                    </span>
                  )}
                </SeverityColor>
              )}
            </Typography>
          ),
        }
      : {
          name: 'OX Severity',
          sortable: true,
          key: 'oxSeverity',
          minWidth: 90,
          maxWidth: 90,
          width: 90,
          formatter: ({ row }) => <OxSeverityCell row={row} />,
        },
  );

  return columns;
};
