import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  Link as MuiLink,
  Theme,
  Button,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { getDependencyIcon } from 'common-icons';
import { SbomReference } from '../../sbom-types';

import pluralize from 'pluralize';
import { MultiLineEllipsis } from 'ox-react-components';

const SbomReferences: FC<React.PropsWithChildren<SbomReferencesProps>> = ({
  references,
  navigateToDependencyGraph,
}) => {
  const { classes } = useStyles();

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      mb={1}
      width='100%'>
      <Typography
        color='text.secondary'
        variant='body2'
        component='span'
        sx={{ mb: 1 }}>
        {pluralize('reference', references.length, true)}
      </Typography>
      <Box display='flex' flexDirection='column' width='100%' gap={1}>
        {references.map((reference, index: number) => {
          const Icon = getDependencyIcon(reference.dependencyType);
          const committerEmail = reference.commit?.committerEmail ?? '';
          const committerName = reference.commit?.committerName ?? '';
          const committedAt = reference.commit?.commitedAt ?? '';
          const location = reference.location ?? '#';

          return (
            <Accordion key={index} className={classes.accordion}>
              <AccordionSummary
                sx={{
                  cursor: 'pointer',
                  alignItems: 'flex-start',
                  overflow: 'hidden',
                }}
                expandIcon={<MdExpandMore />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}>
                <Box
                  display='flex'
                  alignItems='flex-start'
                  gap={1}
                  overflow='hidden'>
                  <Typography variant='caption'>{index + 1}.</Typography>

                  <MultiLineEllipsis lines={2}>
                    {reference.triggerPackage}
                  </MultiLineEllipsis>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Box display='flex' flexDirection='column' gap={1}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    gap={1}
                    alignItems='center'>
                    {Icon && Icon}
                    <Typography variant='caption'>
                      {reference.dependencyType} Dependency
                      {reference.dependencyLevel &&
                        ` (Level ${reference.dependencyLevel})`}
                    </Typography>
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='row'
                    gap={1}
                    alignItems='center'>
                    <Typography
                      variant='caption'
                      color='text.primary'
                      sx={{ fontWeight: 600 }}>
                      Location:
                    </Typography>
                    <Tooltip title={location} arrow>
                      <MuiLink
                        href={reference.locationLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        variant='caption'>
                        {location}
                      </MuiLink>
                    </Tooltip>
                  </Box>

                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={2}
                    alignItems='flex-start'>
                    {committerName && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        gap={1}
                        alignItems='center'>
                        <Typography
                          variant='caption'
                          color='text.primary'
                          sx={{ fontWeight: 600 }}>
                          Committer Name:
                        </Typography>
                        <Tooltip title={committerName} arrow>
                          <Typography variant='caption' color='text.primary'>
                            {committerName}
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}

                    {committerEmail && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        gap={1}
                        alignItems='center'>
                        <Typography
                          variant='caption'
                          color='text.primary'
                          sx={{ fontWeight: 600 }}>
                          Committer Email:
                        </Typography>
                        <Tooltip title={committerEmail} arrow>
                          <MultiLineEllipsis
                            lines={2}
                            variant='caption'
                            color='text.primary'>
                            {committerEmail}
                          </MultiLineEllipsis>
                        </Tooltip>
                      </Box>
                    )}

                    {committedAt && (
                      <Box
                        display='flex'
                        flexDirection='row'
                        gap={1}
                        alignItems='center'>
                        <Typography
                          variant='caption'
                          color='text.primary'
                          sx={{ fontWeight: 600 }}>
                          Committed At:
                        </Typography>
                        <MultiLineEllipsis
                          lines={2}
                          variant='caption'
                          color='text.primary'>
                          {committedAt}
                        </MultiLineEllipsis>
                      </Box>
                    )}
                  </Box>

                  <Box mt={1}>
                    <Button
                      onClick={() => {
                        navigateToDependencyGraph(reference.triggerPackage);
                      }}
                      variant='text'
                      color='primary'
                      size='small'
                      sx={{ textTransform: 'none' }}>
                      Show dependency graph
                    </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  accordion: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    height: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

export interface SbomReferencesProps {
  references: SbomReference[];
  navigateToDependencyGraph: (trigger: string) => void;
}

export default SbomReferences;
