import { _appPages as AppPages } from 'app-navigator';
import { lazy } from 'react';
import { RouteConfig } from './routing-types';

export const publicRoutes: RouteConfig[] = [
  {
    path: AppPages.Home,
    element: lazy(() => import('../../pages/home/HomePage')),
  },
  {
    path: AppPages.OrgLogin,
    element: lazy(() => import('../../pages/login/OrgLoginPage')),
  },
  {
    path: AppPages.OrgSignup,
    element: lazy(() => import('../../pages/login/OrgLoginPage')),
  },
  {
    path: AppPages.OrgSsoLogin,
    element: lazy(() => import('../../pages/sso-login/OrgSsoLoginPage')),
  },
  {
    path: AppPages.InviteOnly,
    element: lazy(() => import('../../pages/invite-only/InviteOnlyPage')),
  },
  {
    path: AppPages.Unauthorized,
    element: lazy(() => import('../../pages/unauthorized/UnauthorizedPage')),
  },
  {
    path: AppPages.EmailVerification,
    element: lazy(
      () => import('../../pages/email-verification/EmailVerificationPage'),
    ),
  },
  {
    path: AppPages.RequestAccess,
    element: lazy(() => import('../../pages/request-access/RequestAccessPage')),
  },
];
