import { AggregationItem } from 'ox-common-types';
import { createStore } from 'store-utils';

export const AggsUtilityStore = createStore<AggsUtilityStoreState>(
  {
    selectedAggs: [],
    selectedAggIds: [],
  },
  'Aggregations Utility Store',
);

export interface AggsUtilityStoreState {
  selectedAggs: AggregationItem[];
  selectedAggIds: string[];
}

export default AggsUtilityStore;
