import { AppPages, navigate } from 'app-navigator';
import { AppStore } from 'app-store/src/app-store';
import { createSimpleAsyncAction } from 'async-utils';
import { AppEvents } from 'common-events';
import {
  FetchFileResponse,
  fileDownloadService,
  fileDownloadStore,
  handleDownloadProgressPercentage,
  handleFileReadyAction,
} from 'download-utils';
import { getJiraTicketsStatusUpdates } from 'jira-logic';
import { debounce, isEmpty, isNull, upperFirst } from 'lodash-es';
import { logger } from 'logging-utils';
import {
  Nullable,
  OneOfValues,
  SeverityType,
  TicketProvider,
  TicketRef,
} from 'ox-common-types';
import { FilterElements, FilterTypeSearch } from 'ox-filter-utils';
import { FileDownloadPoller } from 'polling-utils';
import { closeSnackbar, openSnackbar } from 'snackbar-utils';
import { sendFSCustomEvent, trackAppcuesEvent } from 'telemetry';
import {
  CustomEventValueTypes,
  OXFullStoryEventNames,
} from 'telemetry/src/fullstory/types';
import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../../app/stores/top-bar-store';
import { dashboardStoreActions } from '../../../dashboard/actions/dashboard-actions';
import ScanStore from '../../../new-scan/store/scan-store';
import * as issuesStoreActions from '../../active-issues/store-actions/issue-store-actions';
import { setShowBulkIssueCommentModal } from '../../active-issues/store-actions/issue-store-actions';
import IssuesStore, {
  IssuesStoreState,
} from '../../active-issues/stores/issues-store';
import pipelineIssuesService from '../../pipeline-issues/services';
import * as pipelineIssuesStoreActions from '../../pipeline-issues/store-actions/pipeline-issues-store-actions';
import PipelineIssuesStore from '../../pipeline-issues/stores/pipeline-issues-store';
import resolvedIssuesService from '../../resolved-issues/services';
import * as resolvedIssuesStoreActions from '../../resolved-issues/store-actions/resolved-issue-store-actions';
import ResolvedIssuesStore from '../../resolved-issues/stores/resolved-issues-store';
import issuesService from '../services';
import {
  setDisclaimerAcceptedForIssue,
  setQuestionAndAnswerForIssue,
} from '../store-actions/chat-gpt-store-actions';
import {
  setExcludeCategoryName,
  setExclusionsCategories,
} from '../store-actions/issues-exclusions-store-actions';
import { setSelectedAggs } from '../store-actions/aggs-utility-store-actions';
import {
  BulkIssuesCommentActionType,
  Issue,
  IssueExportsOptions,
  IssueSortKey,
  IssuesOrder,
  LoadIssuesFiltersParams,
  LoadIssuesParams,
  ResetIssuesSeverityResponse,
  SeverityMappingToNumber,
} from '../types/issues-types';
import getIssueDrawerAllTabsData from '../../common/components/IssueDrawer/IssueDrawerTabs/get-issue-drawer-all-tabs-data.gql';
import { isActiveIssuesPage } from '../utils/lazy-filters';
import { TicketingVendor } from '../../../ticketing-module/types/ticketing-types';
import ticketingService from '../../../ticketing-module/services';
import { TicketingVendorsTypes } from '@oxappsec/ox-autogenerated-types-ticketing-service';
import { TicketingTickets } from '../../../ticketing-module/services/get-ticket-by-keys/get-tickets-by-keys';
import {
  GetIssueFallbackSnackBarContent,
  ISSUE_RESOLVED_SNACKBAR_KEY,
} from '../components/GetIssueFallbackSnackBarContent';
import ConnectorsStore from '../../../connectors/stores/connectors-store';
import { ticketingStoreActions } from '../../../ticketing-module/store-actions/ticketing-store-actions';

export const createIssuesActions = (
  store:
    | typeof IssuesStore
    | typeof ResolvedIssuesStore
    | typeof PipelineIssuesStore,
  service:
    | typeof issuesService
    | typeof resolvedIssuesService
    | typeof pipelineIssuesService,
  storeActions:
    | typeof issuesStoreActions
    | typeof resolvedIssuesStoreActions
    | typeof pipelineIssuesStoreActions,
) => {
  const invalidateIssuesCachedQueries = (
    queryNames: string[] = [
      'getIssues',
      'getIssuesConditionalFiltersLazy',
      'getSingleIssueInfo',
    ],
  ) => {
    return issuesService.invalidateIssuesCachedQueries(queryNames);
  };

  const loadIssues = createSimpleAsyncAction(
    async (params?: LoadIssuesParams) => {
      const {
        update = false,
        cache = true,
        limit = 50,
        exclude = false,
        issueId,
        scrollDirection = 'bottom',
        search = '',
      } = params || {};

      if (search) {
        (store as IssuesStoreState).topLevelSearchValue = search;
      }

      if (update) {
        store.offset = 0;
        (store as IssuesStoreState).topOffset = 0;
      }
      if (exclude) {
        store.offset = 0;
      }
      // dirty fix to don't pass scan id for pipeline issues
      const isPipelineIssues =
        window.location.href.indexOf(AppPages.PipelineIssues) !== -1;

      const {
        topOffset,
        offset,
        filterIssuesBy,
        order,
        orderBy,
        totalIssues,
        conditionalFilters,
        totalFilteredIssues,
        topLevelSearchValue,
      } = snapshot(store as IssuesStoreState);

      const { scanID, isScanning, realScanId } = snapshot(ScanStore);
      const { dateRange } = snapshot(TopBarStore);
      const [from, to] = calcDateRange(dateRange);
      const totalItems = totalFilteredIssues || totalIssues;

      if (scrollDirection === 'bottom' && offset > totalItems) return;
      if (scrollDirection === 'top' && topOffset <= 0) return;
      const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
        GlobalDataViewSelector,
      );
      const apiParams = {
        topOffset,
        scrollDirection,
        issueId,
        owners: selectedAppOwnersEmails,
        tagIds: selectedTagIds,
        offset,
        limit,
        ...(isPipelineIssues
          ? {}
          : realScanId
          ? { scanID: realScanId }
          : scanID && { scanID }),

        sort: {
          fields: orderBy,
          order: order,
        },
        dateRange: {
          from,
          to,
        },
        topLevelSearch: topLevelSearchValue,
      };
      const isFiltersExist = !isEmpty(filterIssuesBy);
      const isConditionalFiltersExist = !isEmpty(conditionalFilters);

      if (isConditionalFiltersExist) {
        apiParams['conditionalFilters'] = conditionalFilters;
      } else if (isFiltersExist) {
        apiParams['filters'] = filterIssuesBy;
      }

      const results = await service.getIssues.execute(
        apiParams,
        isScanning ? false : cache === true,
      );
      if (results) {
        dashboardStoreActions.loadDashboardInfo();
        (store as IssuesStoreState).topOffset = results.topOffset;
        (store as IssuesStoreState).selectedPosition = {
          pos: results.selectedPosition,
        };
        storeActions.setStoreIssues(results.issues, update, scrollDirection);
        storeActions.setStoreTotalIssuesFiltered(results.totalFilteredIssues);
        storeActions.setStoreTotalIssues(results.totalIssues);
        storeActions.setStoreTotalResolvedIssues(results.totalResolvedIssues);
        storeActions.setStoreTotalActiveIssues(results.totalActiveIssues);
        storeActions.setStoreIssuesOffset(results.offset);
      }
    },
    {
      asyncState: store.loading,
      errorMessage: 'Failed to load issues',
    },
  );

  const loadFiltersWithoutSearch = createSimpleAsyncAction(
    async (params?: LoadIssuesFiltersParams) => {
      const { cache = true, limit = 100, offset } = params || {};
      const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
        GlobalDataViewSelector,
      );
      const {
        offset: storeOffset,
        filterIssuesBy,
        conditionalFilters,
      } = snapshot(store);
      const { dateRange } = snapshot(TopBarStore);
      const [from, to] = calcDateRange(dateRange);
      const getFiltersParams = {
        owners: selectedAppOwnersEmails,
        tagIds: selectedTagIds,
        offset: offset || storeOffset,
        limit,
        dateRange: { from, to },
      };

      if (conditionalFilters?.length > 0) {
        getFiltersParams['conditionalFilters'] = conditionalFilters;
      } else {
        getFiltersParams['filters'] = filterIssuesBy;
      }

      const results = await service.getIssuesFilters.execute(
        getFiltersParams,
        cache,
      );

      if (results) {
        storeActions.resetSelected();
        storeActions.setStoreIssuesFiltersType(results);
        storeActions.setStoreIssuesFiltersTypeSearch(results);
      }
    },
    {
      asyncState: store.loadingFilters,
      errorMessage: 'Failed to load issues filters',
    },
  );

  const loadStatistics = createSimpleAsyncAction(
    async (
      appName: string,
      cache: boolean = true,
      limit: number = 100,
      offset?: number,
    ) => {
      const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
        GlobalDataViewSelector,
      );
      const { offset: storeOffset, searchValues } = snapshot(store);
      const { dateRange } = snapshot(TopBarStore);
      const [from, to] = calcDateRange(dateRange);
      try {
        storeActions.setIsLoadingApplicationStatistics(true);
        const results = await service.getIssuesFilters.execute(
          {
            owners: selectedAppOwnersEmails,
            tagIds: selectedTagIds,
            offset: offset || storeOffset,
            limit,
            filters: { apps: [appName] },
            dateRange: { from, to },
            search: searchValues,
          },
          cache,
        );

        if (results) {
          storeActions.setStoreIssuesStatistics(results);
        }
      } finally {
        storeActions.setIsLoadingApplicationStatistics(false);
      }
    },
    {
      asyncState: store.loadingFilters,
      errorMessage: 'Failed to load application statistics',
    },
  );

  const updateJiraTicketsLatestStatus = async () => {
    const { selectedIssue } = snapshot(store);
    const { isActiveJiraConnection } = snapshot(ConnectorsStore);

    if (selectedIssue && isActiveJiraConnection) {
      const filteredTickets = selectedIssue.tickets.filter(
        ticket => ticket.provider === TicketingVendor.Jira.toLowerCase(),
      );
      const selectedIssuejiraTicketsIds = filteredTickets.map(
        ticket => ticket.key,
      );

      storeActions.setLoadingJiraStatusUpdate(true);
      const jiraLatestStatusRes = await getJiraTicketsStatusUpdates(
        selectedIssuejiraTicketsIds,
        selectedIssue.issueId,
        false,
      );
      if (jiraLatestStatusRes) {
        const jiraTicketStatus = jiraLatestStatusRes.map(
          ({ key, self, id, fields }) => ({
            link: self,
            ticketId: id,
            key,
            fields,
            provider: TicketProvider.Jira,
          }),
        );
        storeActions.setDrawerJiraTicketsStatus(jiraTicketStatus);
        storeActions.setJiraTicketsToRawIssue(
          selectedIssue.issueId,
          jiraTicketStatus,
        );
      }

      storeActions.setLoadingJiraStatusUpdate(false);
    }
  };

  const updateTicketsDetailByProvider = async () => {
    const { selectedIssue } = snapshot(store);
    ticketingStoreActions.setIsLoadingTicketingStatus(true);
    const tickets = selectedIssue?.tickets;
    let ticketsWithDetails: TicketRef[] = [];
    let ticketsStatuses: TicketingTickets = [];

    const ticketsByProvider = tickets?.reduce((acc, ticket) => {
      const { provider } = ticket;
      if (!acc[provider]) {
        acc[provider] = [];
      }
      acc[provider].push(ticket);
      return acc;
    }, {} as Record<string, typeof tickets>);

    for (const provider in ticketsByProvider) {
      if (provider === TicketingVendor.Jira.toLowerCase()) {
        //TODO: change it to support jira after migrate jira to new ticketing service
        continue;
      }

      const keys = ticketsByProvider[provider].map(ticket => ticket.key);
      ticketsStatuses = (await ticketingService.getTicketsByKeys.execute({
        ticketingVendor: provider as TicketingVendorsTypes,
        keys: keys as string[],
        issueId: selectedIssue?.issueId,
      })) as TicketingTickets;

      ticketsWithDetails = [
        ...((ticketsWithDetails || []) as TicketRef[]),
        ...((ticketsStatuses || []) as unknown[]),
      ] as TicketRef[];
    }
    storeActions.setDrawerTicketsStatus(ticketsWithDetails);
    ticketingStoreActions.setIsLoadingTicketingStatus(false);
  };

  const getIssue = async (issueId: string): Promise<void> => {
    storeActions.setSelectedIssueId(issueId);
    storeActions.setLoadingIssue(true);
    const result = await service.getIssue.execute({
      issueId,
    });

    if (result) {
      if (result && store.selectedIssueId === result.issueId) {
        storeActions.setSelectedIssue(result);
        storeActions.setSelectedIssueId(result.issueId);
      }
      if (result.gptInfo && result.gptInfo.gptResponse) {
        setQuestionAndAnswerForIssue(
          issueId,
          result.gptInfo.gptResponse,
          'Describe the issue in more detail',
        );
        setDisclaimerAcceptedForIssue(issueId);
      }
      if (result?.exclusions) {
        setExclusionsCategories(result?.exclusions);
      }
      if (result && store.selectedIssueId === result.issueId) {
        trackAppcuesEvent(AppEvents.Issues.IssueSelected);
        storeActions.setSelectedIssue(result);
        setSelectedAggs([]);
        storeActions.setSelectedIssueId(result.issueId);
        storeActions.openIssueModal();
        updateJiraTicketsLatestStatus();
        updateTicketsDetailByProvider();
        setExcludeCategoryName('');
      }
    } else {
      openSnackbar(`Issue no longer exists`, {
        variant: 'error',
      });
    }
    storeActions.setLoadingIssue(false);
  };

  const getIssueDrawerData = async (
    issueId: string,
    shouldSearchInResolvedIssues?: boolean,
  ): Promise<void> => {
    // TODO - refactor this function after refactoring the issue actions
    storeActions.setSelectedIssueId(issueId);
    storeActions.setLoadingIssue(true);
    const result = await issuesService.getIssueDetailsByQuery.execute({
      issueId,
      query: getIssueDrawerAllTabsData,
    });
    storeActions.setLoadingIssue(false);

    if (!result) {
      const resolvedIssue =
        shouldSearchInResolvedIssues &&
        (await resolvedIssuesService.getIssue.execute({
          issueId,
        }));
      if (resolvedIssue) {
        openSnackbar(GetIssueFallbackSnackBarContent(issueId), {
          variant: 'success',
          persist: true,
          key: ISSUE_RESOLVED_SNACKBAR_KEY,
        });

        return;
      } else {
        openSnackbar(`Issue no longer exists`, {
          variant: 'error',
        });
        return;
      }
    }

    if (store.selectedIssueId === result.issueId) {
      storeActions.setSelectedIssue(result);
      storeActions.setSelectedIssueId(result.issueId);
    }
    if (result.gptInfo && result.gptInfo.gptResponse) {
      setQuestionAndAnswerForIssue(
        issueId,
        result.gptInfo.gptResponse,
        'Describe the issue in more detail',
      );
      setDisclaimerAcceptedForIssue(issueId);
    }
    if (result?.exclusions) {
      setExclusionsCategories(result?.exclusions);
    }
    if (store.selectedIssueId === result.issueId) {
      trackAppcuesEvent(AppEvents.Issues.IssueSelected);
      storeActions.setSelectedIssue(result);
      setSelectedAggs([]);
      storeActions.setSelectedIssueId(result.issueId);
      storeActions.openIssueModal();
      updateJiraTicketsLatestStatus();
      updateTicketsDetailByProvider();
      setExcludeCategoryName('');
    }
  };

  const setIssueDrawer = async (
    issueId: string,
    shouldSearchInResolvedIssues?: boolean,
  ): Promise<void> => {
    const { selectedIssueId } = snapshot(store);
    if (issueId === selectedIssueId) return;
    storeActions.setSelectedIssueId(issueId);
    storeActions.setLoadingIssue(true);

    if (isActiveIssuesPage()) {
      await getIssueDrawerData(issueId, shouldSearchInResolvedIssues);
    } else {
      await getIssue(issueId);
    }
  };

  const clearSelectedIssue = () => {
    storeActions.setSelectedIssueId(null);
    storeActions.setSelectedIssue(null);
  };

  const setLoadingIssue = (value: boolean) => {
    store.loadingIssue = value;
  };

  const handleIssueRowClick = debounce(
    (issueId: string, page?: OneOfValues<typeof AppPages>) => {
      let currentUrl = new URL(window.location.href);
      let params = new URLSearchParams(currentUrl.search);
      params.set('issueId', issueId);
      navigate(!!page ? page : AppPages.ActiveIssues, params.toString());
      setIssueDrawer(issueId);
    },
    500,
  );

  const sortIssues = (key?: IssueSortKey, defaultSort?: IssueSortKey) => {
    const resetSort = !key;
    if (resetSort) {
      if (store.orderBy[0] === (defaultSort || IssueSortKey.Severity)) {
        store.order[0] =
          store.order[0] === IssuesOrder.Desc
            ? IssuesOrder.Asc
            : IssuesOrder.Desc;
      } else {
        store.order[0] = IssuesOrder.Desc;
        store.orderBy[0] = defaultSort || IssueSortKey.Severity;
      }
    } else {
      store.orderBy[0] = key;
      store.order[0] =
        store.order[0] === IssuesOrder.Asc ? IssuesOrder.Desc : IssuesOrder.Asc;
    }

    store.offset = 0;
    loadIssues({ update: true });
  };

  const handleSearchFilterType = debounce(
    (searchInput: string, filterType: string) => {
      if (searchInput === '') {
        const index = store.searchValues.findIndex(
          key => key.fieldName === filterType,
        );
        store.searchValues[index].value = '';

        return;
      }
      storeActions.setSearchValues(filterType, searchInput);
      issuesStoreActions.getIssuesOpenFilterItems();
    },
    300,
  );

  const handleSearchFilterTypeWithoutNewSearch = (
    searchInput: string,
    filterType: string,
  ) => {
    if (searchInput === '') {
      const index = store.searchValues.findIndex(
        key => key.fieldName === filterType,
      );
      store.searchValues[index].value = '';
      return;
    }
    const { issuesFiltersType } = snapshot(store);
    if (issuesFiltersType && filterType) {
      const newFilteredTypes =
        issuesFiltersType[filterType] &&
        issuesFiltersType[filterType].filter(
          (issueFilterType: FilterElements) =>
            issueFilterType.label
              .toLocaleLowerCase()
              .includes(searchInput.toLowerCase()),
        );
      storeActions.setSearchValues(filterType, searchInput);

      storeActions.setStoreIssuesFiltersTypeSearch({
        ...issuesFiltersType,
        [FilterTypeSearch[filterType]]: newFilteredTypes,
      });
    }
  };

  const setMenuIssueId = (issueId: Nullable<Issue['issueId']>) => {
    store.menuIssueId = issueId;
  };

  const addingComment = async () => {
    if (!store.selectedIssue) {
      return;
    }
    try {
      const isEdit = store.selectedIssue.comment ? true : false;
      setLoadingIssueComment(true);
      invalidateIssuesCachedQueries();
      const result = await service.addCommentToIssue.execute({
        issueId: store.selectedIssue.issueId,
        comment: store.issueComment,
      });
      if (!result) {
        throw new Error(
          'Adding comment to issue failed due to an unknown error',
        );
      } else {
        issuesStoreActions.setCommentModal(false);
        issuesStoreActions.setIssueComment();
        openSnackbar(
          `The comment was ${isEdit ? `edited` : `added`} successfully`,
          {
            variant: 'success',
          },
        );
      }
      setLoadingIssueComment(false);
      issuesStoreActions.getIssuesOpenFilterItems();
      loadIssues({ update: true, cache: false });
    } catch (error) {
      openSnackbar(`Comment was not created, please try again`, {
        variant: 'error',
      });
      issuesStoreActions.setCommentModal(false);
      setLoadingIssueComment(false);
      logger.error(`Issue comment failed due to an error: ${error}`);
    }
  };

  const deleteComment = async () => {
    if (!store.selectedIssue) {
      return;
    }
    try {
      setLoadingDeleteIssueComment(true);
      invalidateIssuesCachedQueries();
      const result = await service.deleteCommentIssue.execute({
        issueId: store.selectedIssue.issueId,
      });

      if (!result) {
        throw new Error(
          'Delete comment to issue failed due to an unknown error',
        );
      } else {
        issuesStoreActions.setCommentModal(false);
        issuesStoreActions.setIssueComment(true);
        issuesStoreActions.setIssueCommentTempValue('');
        openSnackbar('The comment was delete successfully', {
          variant: 'success',
        });
      }
      setLoadingDeleteIssueComment(false);
      issuesStoreActions.getIssuesOpenFilterItems();
      loadIssues({ update: true, cache: false });
      issuesStoreActions.closeIssuesDrawer();
    } catch (error) {
      issuesStoreActions.closeIssuesDrawer();
      setLoadingDeleteIssueComment(false);
      openSnackbar(`Comment was not deleted, please try again`, {
        variant: 'error',
      });
      logger.error(`Delete issue comment failed due to an error: ${error}`);
    }
  };

  const setLoadingIssueComment = (loading: boolean) => {
    store.isAddingCommentLoading = loading;
  };

  const setLoadingDeleteIssueComment = (loading: boolean) => {
    store.isDeleteCommentLoading = loading;
  };

  const exportIssuesToFile = async ({
    includeIssuesFilters,
    flattenAgg,
    exportFileType,
  }: IssueExportsOptions) => {
    try {
      const { user } = snapshot(AppStore);
      sendFSCustomEvent(OXFullStoryEventNames.exportedIssues, {
        orgId: {
          type: CustomEventValueTypes.String,
          value: user?.org_id,
        },
        exportType: {
          type: CustomEventValueTypes.String,
          value: exportFileType,
        },
      });
      const { order, orderBy, totalIssues } = snapshot(store);
      const { conditionalFilters, topLevelSearchValue } = snapshot(IssuesStore);
      const { scanID, realScanId } = snapshot(ScanStore);
      const { dateRange } = snapshot(TopBarStore);
      const { downloadProgress } = snapshot(fileDownloadStore);
      const [from, to] = calcDateRange(dateRange);
      const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
        GlobalDataViewSelector,
      );
      const scan = realScanId ? { scanID: realScanId } : scanID && { scanID };
      const csvIssueSnackbarKey = 'download-csv-Issues-snackbar';
      fileDownloadStore.issuesCsvDownloadInProgress = true;
      openSnackbar(
        `Exporting Issues to ${exportFileType === 'csv' ? 'CSV' : 'PDF'}`,
        {
          variant: 'default',
          persist: true,
          key: csvIssueSnackbarKey,
        },
        {
          hideCloseIcon: false,
          isShowingSpinner: true,
          spinnerVariant: 'determinate',
          value: downloadProgress,
          valueCb: () => {
            return snapshot(fileDownloadStore).downloadProgress;
          },
        },
      );
      const { isDemoEnabled } = snapshot(ScanStore);
      let response: { requestId: string } | null;
      if (exportFileType === 'pdf') {
        const apiParams = {
          isDemo: isDemoEnabled,
          scanId: realScanId || (scan ? scan.scanID : Date.now().toString()),
        };

        if (includeIssuesFilters) {
          apiParams['conditionalFilters'] = conditionalFilters;
        }
        response = await issuesService.exportIssuesPdf.execute(apiParams);
      } else if (exportFileType === 'csv') {
        const exportsOptions = {
          flattenAgg,
          isDemoEnabled,
        };
        const apiParams = includeIssuesFilters
          ? {
              ...scan,
              sort: {
                fields: orderBy,
                order: order,
              },
              dateRange: {
                from,
                to,
              },
              owners: selectedAppOwnersEmails,
              tagIds: selectedTagIds,
              exportsOptions,
            }
          : { ...scan, exportsOptions };

        if (includeIssuesFilters) {
          apiParams['conditionalFilters'] = conditionalFilters;
        }
        if (topLevelSearchValue) {
          apiParams['topLevelSearch'] = topLevelSearchValue;
        }

        response = await issuesService.getExportedIssues.execute(apiParams);
      } else {
        response = null;
      }
      if (isNull(response)) {
        closeSnackbar(csvIssueSnackbarKey);
        openSnackbar(
          `Failed generating issues ${exportFileType}, please try again later`,
          {
            variant: 'error',
          },
        );
        return;
      }
      const poller = new FileDownloadPoller<
        FetchFileResponse['fetchReadyFile']
      >(
        () => {
          if (!response?.requestId) {
            throw new Error(
              `response object or requestId were null: ${response}`,
            );
          }
          return fileDownloadService.fetchReadyFile.execute({
            requestId: response.requestId,
          });
        },
        (response: Nullable<FetchFileResponse['fetchReadyFile']>) => {
          if (response) {
            if (response.error) {
              closeSnackbar(csvIssueSnackbarKey);
              openSnackbar(
                `Failed generating issues ${exportFileType}, please try again later`,
                { variant: 'error' },
              );
              return false;
            }

            handleDownloadProgressPercentage(totalIssues);

            if (response.isFileReady) {
              handleFileReadyAction(
                response,
                `Successfully downloaded ${
                  includeIssuesFilters ? 'filtered' : 'all'
                } Issues ${exportFileType === 'csv' ? 'CSV' : 'PDF'} file`,
              );
            }
            return !response.isFileReady;
          } else {
            return false;
          }
        },
        () => {},
        () => {
          fileDownloadStore.downloadProgress = 0;
          fileDownloadStore.issuesCsvDownloadInProgress = false;
          closeSnackbar(csvIssueSnackbarKey);
        },
      );

      poller.startPolling();
      setTimeout(() => {
        poller.cancelPolling();
      }, 1200000);
    } catch (error) {
      logger.error(error);
      openSnackbar(
        `Export issues to CSV file failed. Please try again later...`,
        {
          variant: 'warning',
        },
      );
    }
  };

  const exportToolsIssuesToCSVfile = async ({
    includeIssuesFilters,
  }: IssueExportsOptions) => {
    try {
      const { order, orderBy, totalIssues } = snapshot(store);
      const { conditionalFilters } = snapshot(IssuesStore);
      const { scanID, realScanId } = snapshot(ScanStore);
      const { dateRange } = snapshot(TopBarStore);
      const { downloadProgress } = snapshot(fileDownloadStore);
      const [from, to] = calcDateRange(dateRange);
      const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
        GlobalDataViewSelector,
      );
      const scan = realScanId ? { scanID: realScanId } : scanID && { scanID };
      const csvIssueSnackbarKey = 'download-csv-tools-issues-snackbar';
      fileDownloadStore.toolsIssuesCsvDownloadInProgress = true;
      openSnackbar(
        `Exporting tools diff Issues to CSV`,
        {
          variant: 'default',
          persist: true,
          key: csvIssueSnackbarKey,
        },
        {
          hideCloseIcon: false,
          isShowingSpinner: true,
          spinnerVariant: 'determinate',
          value: downloadProgress,
          valueCb: () => {
            return snapshot(fileDownloadStore).downloadProgress;
          },
        },
      );
      const { isDemoEnabled } = snapshot(ScanStore);

      const exportsOptions = {
        isDemoEnabled,
      };
      const apiParams = includeIssuesFilters
        ? {
            ...scan,

            sort: {
              fields: orderBy,
              order: order,
            },
            dateRange: {
              from,
              to,
            },
            owners: selectedAppOwnersEmails,
            tagIds: selectedTagIds,
            exportsOptions,
          }
        : { ...scan, exportsOptions };

      if (includeIssuesFilters) {
        apiParams['conditionalFilters'] = conditionalFilters;
      }

      const response = await issuesService.getExportedToolsIssues.execute(
        apiParams,
      );

      if (isNull(response)) {
        closeSnackbar(csvIssueSnackbarKey);
        openSnackbar(
          `Failed generating diff tools issues csv, please try again later`,
          {
            variant: 'error',
          },
        );
        return;
      }

      const poller = new FileDownloadPoller<
        FetchFileResponse['fetchReadyFile']
      >(
        () => {
          return fileDownloadService.fetchReadyFile.execute({
            requestId: response.requestId,
          });
        },
        (response: Nullable<FetchFileResponse['fetchReadyFile']>) => {
          if (response) {
            if (response.error) {
              closeSnackbar(csvIssueSnackbarKey);
              openSnackbar(
                `Failed generating diff tools issues csv, please try again later`,
                { variant: 'error' },
              );
              return false;
            }

            handleDownloadProgressPercentage(totalIssues);

            if (response.isFileReady) {
              handleFileReadyAction(
                response,
                'Successfully downloaded diff tools issues CSV',
              );
            }
            return !response.isFileReady;
          } else {
            return false;
          }
        },
        () => {},
        () => {
          fileDownloadStore.downloadProgress = 0;
          fileDownloadStore.toolsIssuesCsvDownloadInProgress = false;
          closeSnackbar(csvIssueSnackbarKey);
        },
      );

      poller.startPolling();
      setTimeout(() => {
        poller.cancelPolling();
      }, 1200000);
    } catch (error) {
      logger.error(error);
      openSnackbar(
        `Export diff tools to CSV file failed. Please try again later...`,
        {
          variant: 'warning',
        },
      );
    }
  };

  const bulkChangeSeverity = async (severity?: SeverityType, reset = false) => {
    const bulkChangeSeverityKey = 'change-issues-severity';
    try {
      const { selected, selectedIssue } = snapshot(store);
      const issueIds = (Object.values(selected) as Issue[])
        .filter(issue => !isNull(issue))
        .map(issue => issue.issueId);
      issuesStoreActions.setShowBulkChangeSeverityModal(false);
      let res: Nullable<boolean> | Nullable<ResetIssuesSeverityResponse>;
      openSnackbar(
        `${reset ? 'Reset' : 'Update'} severity for ${
          issueIds.length
        } selected issues in progress`,
        {
          variant: 'info',
          persist: true,
          key: bulkChangeSeverityKey,
        },
        { hideCloseIcon: true, isShowingSpinner: true },
      );
      invalidateIssuesCachedQueries();
      if (reset) {
        res = await issuesService.bulkResetIssuesSeverity.execute({
          issueIds,
        });
      } else {
        const newSeverity = severity && SeverityMappingToNumber(severity);
        res = await issuesService.bulkUpdateIssuesSeverity.execute({
          issueIds,
          severity: newSeverity!,
        });
      }

      if (isNull(res)) {
        throw new Error('invalid response');
      }
      loadIssues({ update: true, cache: false });
      selectedIssue && getIssue(selectedIssue.issueId);
      issuesStoreActions.getIssuesOpenFilterItems();
      storeActions.resetSelected();
      closeSnackbar(bulkChangeSeverityKey);
      openSnackbar(
        `Severity ${reset ? 'reset' : 'updated'} successfully for the ${
          issueIds.length
        } selected issues!`,
        {
          variant: 'success',
        },
      );
    } catch (error) {
      logger.error(error);
      closeSnackbar(bulkChangeSeverityKey);
      openSnackbar(
        `Faild to ${reset ? 'reset' : 'update'} severity, please try again.`,
        {
          variant: 'error',
        },
      );
    }
  };

  const upsertBulkCommentToIssues = async (
    action: BulkIssuesCommentActionType,
    comment?: string,
  ) => {
    setShowBulkIssueCommentModal(false);
    const bulkIssuesCommentKey = 'bulk-issues-comment';
    try {
      const { selected } = snapshot(store);
      let issuesId: Nullable<string[]> = null;
      if (action === 'Delete') {
        issuesId = (Object.values(selected) as Issue[])
          .filter(issue => !isNull(issue) && !isEmpty(issue.comment))
          .map(issue => issue.issueId);
      } else {
        issuesId = (Object.values(selected) as Issue[])
          .filter(issue => !isNull(issue))
          .map(issue => issue.issueId);
      }

      issuesStoreActions.setShowBulkChangeSeverityModal(false);
      let res: Nullable<boolean> = null;
      openSnackbar(
        `${action} comment for ${issuesId.length} selected issues in progress`,
        {
          variant: 'info',
          persist: true,
          key: bulkIssuesCommentKey,
        },
        { hideCloseIcon: true, isShowingSpinner: true },
      );

      invalidateIssuesCachedQueries();
      res = await issuesService.upsertBulkCommentToIssues.execute({
        issuesId,
        comment,
        action,
      });

      if (isNull(res) || !res) {
        throw new Error('invalid response');
      }
      loadIssues({ update: true, cache: false });
      const { selectedIssue } = snapshot(store);
      selectedIssue && getIssue(selectedIssue.issueId);
      issuesStoreActions.getIssuesOpenFilterItems();
      storeActions.resetSelected();
      closeSnackbar(bulkIssuesCommentKey);
      enum ActionMap {
        Delete = 'deleted',
        Edit = 'edited',
        Add = 'added',
      }
      openSnackbar(
        `Comments were ${ActionMap[action]} successfully for the ${issuesId.length} selected issues!`,
        {
          variant: 'success',
        },
      );
    } catch (error) {
      logger.error(error);
      closeSnackbar(bulkIssuesCommentKey);
      openSnackbar(
        `Faild to ${action.toLowerCase()} commets, please try again.`,
        {
          variant: 'error',
        },
      );
    }
  };

  const changeSeverity = async () => {
    if (
      IssuesStore.selectedIssue &&
      SeverityMappingToNumber(IssuesStore.selectedIssue?.newSeverity) ===
        IssuesStore.selectedIssue?.originalSeverity
    ) {
      resetSeverity();
      issuesStoreActions.setChangeSeverityModal(false);
    } else if (IssuesStore.selectedIssue) {
      invalidateIssuesCachedQueries();
      const res = await issuesService.updateIssueSeverity.execute({
        issueId: IssuesStore.selectedIssue?.issueId,
        severity: IssuesStore.selectedIssue.newSeverity
          ? SeverityMappingToNumber(IssuesStore.selectedIssue?.newSeverity)
          : SeverityMappingToNumber(
              upperFirst(IssuesStore.selectedIssue?.severity),
            ),
      });
      if (res) {
        openSnackbar('Severity was changed successfully!', {
          variant: 'success',
        });
        issuesStoreActions.setChangeSeverityModal(false);
        getIssue(IssuesStore.selectedIssue.issueId);
        issuesStoreActions.getIssuesOpenFilterItems();
        loadIssues({ update: true, cache: false });
      } else {
        openSnackbar('Faild to update severity, please try again.', {
          variant: 'error',
        });
        issuesStoreActions.setChangeSeverityModal(false);
      }
    }
  };

  const resetSeverity = async () => {
    if (IssuesStore.selectedIssue) {
      invalidateIssuesCachedQueries();
      const res = await issuesService.resetIssueSeverity.execute({
        issueId: IssuesStore.selectedIssue?.issueId,
      });
      if (res) {
        openSnackbar('Severity was reset successfully!', {
          variant: 'success',
        });
        issuesStoreActions.setChangeSeverityModal(false);
        getIssue(IssuesStore.selectedIssue.issueId);
        issuesStoreActions.getIssuesOpenFilterItems();
        loadIssues({ update: true, cache: false });
      } else {
        openSnackbar('Faild to reset severity, please try again.', {
          variant: 'error',
        });
        issuesStoreActions.setChangeSeverityModal(false);
      }
    }
  };

  return {
    invalidateIssuesCachedQueries,
    loadIssues,
    loadFiltersWithoutSearch,
    setIssueDrawer,
    clearSelectedIssue,
    setLoadingIssue,
    handleIssueRowClick,
    sortIssues,
    handleSearchFilterType,
    handleSearchFilterTypeWithoutNewSearch,
    setMenuIssueId,
    loadStatistics,
    addingComment,
    deleteComment,
    setLoadingIssueComment,
    updateJiraTicketsLatestStatus,
    exportIssuesToFile,
    changeSeverity,
    bulkChangeSeverity,
    upsertBulkCommentToIssues,
    resetSeverity,
    getIssue,
    exportToolsIssuesToCSVfile,
    updateTicketsDetailByProvider,
  };
};
