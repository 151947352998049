import { Palette } from '@mui/material';
import {
  OxCategoriesKeys,
  OxCategoriesNames,
} from '@oxappsec/ox-consolidated-categories';
import { IconType, Nullable, OneOfValues } from 'ox-common-types';
import { ChangeEvent } from 'react';
import { RepoNode } from '../onboarding/onboarding-types';
import { CredentialsType } from './connector-configure-types';
import { WithDisabledIfUnauthorizedProps } from '../permissions/higher-order-components/withDisabledIfUnauthorized';

export enum EConnectorFields {
  id = 'id',
  name = 'name',
  displayName = 'displayName',
  description = 'description',
  hostURL = 'hostURL',
  iconURL = 'iconURL',
  family = 'family',
  credentialsType = 'credentialsType',
  credentialsTypes = 'credentialsTypes',
  credentials = 'credentials',
  isConfigured = 'isConfigured',
  errorMessage = 'errorMessage',
  isResourceAvailable = 'isResourceAvailable',
  resourceOptions = 'resourceOptions',
  isOxBuiltIn = 'isOxBuiltIn',
  isOpenSource = 'isOpenSource',
  openSourceWebsiteUrl = 'openSourceWebsiteUrl',
  openSourceLicense = 'openSourceLicense',
  openSourceAuthor = 'openSourceAuthor',
  defaultEnabled = 'defaultEnabled',
  comingSoon = 'comingSoon',
  isDemoEnabled = 'isDemoEnabled',
  aliasFor = 'aliasFor',
  identityProviderInfo = 'identityProviderInfo',
  gitHubAppInfo = 'gitHubAppInfo',
  bitbucketAppInfo = 'bitbucketAppInfo',
  awsCloudFormationInfo = 'awsCloudFormationInfo',
  awsCloudFormationOrganizationInfo = 'awsCloudFormationOrganizationInfo',
  connectionInstructions = 'connectionInstructions',
  isConnectorInvalid = 'isConnectorInvalid',
  isDiscovered = 'isDiscovered',
  isEmptyOfRepos = 'isEmptyOfRepos',
  connectorToUse = 'connectorToUse',
  connectorStatus = 'connectorStatus',
  badgeIcon = 'badgeIcon',
  apiAccessKey = 'apiAccessKey',
  connectorExplanation = 'connectorExplanation',
  optionalInputFields = 'optionalInputFields',
  conditionalOptionalTabs = 'conditionalOptionalTabs',
  dependsOn = 'dependsOn',
  dependsOnDisplayName = 'dependsOnDisplayName',
  disableConnectOrSwitch = 'disableConnectOrSwitch',
  dependsOnId = 'dependsOnId',
  dependsOnConnector = 'dependsOnConnector',
  dependencyConnectors = 'dependencyConnectors',
  externalLink = 'externalLink',
  textExternalLink = 'textExternalLink',
  hideConnector = 'hideConnector',
  aggregatedConnectors = 'aggregatedConnectors',
  ffKey = 'ffKey',
  beta = 'beta',
  resources = 'resources',
  ffKeyResourceAvailable = 'ffKeyResourceAvailable',
}

export interface Connector {
  [EConnectorFields.id]: string;
  [EConnectorFields.name]: string;
  [EConnectorFields.displayName]: string;
  [EConnectorFields.description]: string;
  [EConnectorFields.hostURL]: string;
  [EConnectorFields.apiAccessKey]: string;
  [EConnectorFields.iconURL]: string;
  [EConnectorFields.family]: OneOfValues<typeof OxCategoriesKeys>;
  [EConnectorFields.credentialsType]: CredentialsType;
  [EConnectorFields.credentialsTypes]: CredentialsType[];
  [EConnectorFields.credentials]: Credential[];
  [EConnectorFields.isConfigured]: boolean;
  [EConnectorFields.errorMessage]?: string;
  [EConnectorFields.isResourceAvailable]: boolean;
  [EConnectorFields.ffKeyResourceAvailable]?: string;
  [EConnectorFields.resourceOptions]?: ResourceOptions;
  [EConnectorFields.isOxBuiltIn]: boolean;
  [EConnectorFields.isOpenSource]: boolean;
  [EConnectorFields.openSourceWebsiteUrl]?: string;
  [EConnectorFields.openSourceLicense]?: string;
  [EConnectorFields.openSourceAuthor]?: string;
  [EConnectorFields.defaultEnabled]?: boolean;
  [EConnectorFields.comingSoon]?: boolean;
  [EConnectorFields.isDemoEnabled]?: boolean;
  [EConnectorFields.aliasFor]?: string;
  [EConnectorFields.identityProviderInfo]?: IdentityProviderInfo;
  [EConnectorFields.gitHubAppInfo]?: GitHubAppInfo;
  [EConnectorFields.bitbucketAppInfo]?: BitbucketAppInfo;
  [EConnectorFields.awsCloudFormationInfo]?: AWSCloudFormationInfo;
  [EConnectorFields.awsCloudFormationOrganizationInfo]?: AWSCloudFormationInfo;
  [EConnectorFields.connectionInstructions]?: ConnectorInstructionsType[];
  [EConnectorFields.isConnectorInvalid]?: boolean;
  [EConnectorFields.isDiscovered]?: boolean;
  [EConnectorFields.isEmptyOfRepos]?: boolean;
  [EConnectorFields.connectorToUse]?: Connector;
  [EConnectorFields.connectorStatus]: ConnectorStatus;
  [EConnectorFields.badgeIcon]?: IconType;
  [EConnectorFields.connectorExplanation]?: string;
  [EConnectorFields.optionalInputFields]?: OptionalConnectorInput[];
  [EConnectorFields.conditionalOptionalTabs]?: ConditionalOptionalTabs[];
  [EConnectorFields.dependsOn]?: string;
  [EConnectorFields.dependsOnDisplayName]?: string;
  [EConnectorFields.disableConnectOrSwitch]?: boolean;
  [EConnectorFields.dependsOnId]?: string;
  [EConnectorFields.dependsOnConnector]?: Connector;
  [EConnectorFields.dependencyConnectors]?: string[];
  [EConnectorFields.externalLink]?: string;
  [EConnectorFields.textExternalLink]?: string;
  [EConnectorFields.hideConnector]?: boolean;
  [EConnectorFields.aggregatedConnectors]?: Connector[];
  [EConnectorFields.ffKey]?: string;
  [EConnectorFields.beta]?: boolean;
  [EConnectorFields.resources]?: ResourceItem[];
}

export interface ResourceItem {
  type: string;
}

export interface ResourceOptions {
  resourceName: ResourceName;
  hideMonitorNewResourcesCheckbox?: boolean;
  showSettingsPerResource?: boolean;
}

export interface ResourceAppItem {
  id: string;
  name: string;
  connectorId: string;
  resourceOrgName?: string;
}

export interface ConnectorBranches {
  [appName: string]: Branch[];
}

export interface BranchesPerConnector {
  [connectorId: string]: ConnectorBranches;
}

export enum ResourceName {
  repos = 'repos',
  clusters = 'clusters',
  containers = 'containers',
  images = 'images',
  branches = 'branches',
  accounts = 'accounts',
  applications = 'applications',
  organizations = 'organizations',
  projects = 'projects',
  subscriptions = 'subscriptions',
}

export interface OptionalConnectorInput {
  name: string;
  credsTypes: CredentialsType[];
  inputType: InputTypes;
  key: string;
  ffKey: string;
  value?: string;
}

export enum InputTypes {
  password = 'password',
  text = 'text',
  number = 'number',
  boolean = 'boolean',
}

export interface ConditionalOptionalTabs {
  tabTitle: string;
  tabInputs: {
    inputType: InputTypes;
    inputTitle: string;
    inputName: string;
  }[];
}

export enum CollectionType {
  Collectors = 'collectors',
}

export interface ConnectorStatus {
  title: ConnectorStatusTitle;
  color: keyof Palette['badge'];
}

export enum ConnectorStatusTitle {
  NotConnected = 'Discovered - Not Connected',
  ConnectivityIssue = 'Connectivity Issue',
  Connected = 'Connected',
}

export interface ConnectorConfigureResponse {
  id: string;
  isConfigured: boolean;
}

export interface CredentialsInput {
  name: string;
  password: string;
  token: string;
  clientId: string;
  secretKey: string;
  awsRoleArn: string;
  awsExternalId: string;
  awsAccessKey: string;
  awsAccessSecret: string;
  tenant: string;
  projectId: string;
  apiAccessKey: string;
  apiSecretKey: string;
  tenantId: string;
  clientSecret: string;
  subscriptionId?: string;
  organizationId: string;
  apiKey: string;
  apiUrl: string;
  appId: string;
  webhookUrl: string;
  optionalFields?: {
    [key: string]: string | boolean;
  };
  extraOptionalCreds?: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

export interface CredentialFormFields {
  name: string;
  password: string;
  gitPassword: string;
  gitUsername: string;
  token: string;
  hostURL: string;
  clientId: string;
  secretKey: string;
  awsRoleArn: string;
  awsExternalId: string;
  awsAccessKey: string;
  awsAccessSecret: string;
  tenant: string;
  projectId: string;
  apiAccessKey: string;
  apiSecretKey: string;
  tenantId: string;
  clientSecret: string;
  subscriptionId?: string;
  organizationId: string;
  apiKey: string;
  apiUrl: string;
  appId: string;
  webhookUrl: string;
  optionalFields?: {
    [key: string]: string | boolean;
  };
  optionalTabsFields: {
    [tabName: string]: {
      [inputName: string]: string;
    };
  };
}

export interface GetConnectorInput {
  connectorID: string;
}

export interface AddCredentialsInput {
  connectorID: string;
  hostURL: string;
  credentialsType: CredentialsType;
  credentialsInput: CredentialsInput;
  credentialsName?: string;
}

export interface RemoveCredentialsInput {
  connectorID: string;
  credentialsIndex: number;
  credentialsId?: string;
}

export interface CredentialsConfigureResponse {
  id: string;
  hostURL: string;
  isConfigured: boolean;
  credentials: Credential[];
}

export interface ValidateSingleConnectorResponse {
  verifySingleConnectorCredentials: {
    credentialsAreValid: boolean;
    noReposFound: boolean;
    validationMessage?: string;
  };
}

export interface ClustersConnectionStatus {
  successConnectedClusters: String[];
  failureConnectedClusters: String[];
}

export interface GetConnectorScriptResult {
  getConnectorScript: GetConnectorScriptResponse;
}

export interface GetConnectorScriptResponse {
  connectorScript: string;
  fileName: string;
}

export enum ConnectorScriptTypes {
  eksBash = 'eksBash',
  eksPython = 'eksPython',
  azureCloudBash = 'azureCloudBash',
}

export interface GetConnectorScriptInput {
  connectorScriptType: ConnectorScriptTypes;
}

export interface ValidateAllCredentialsResponse {
  verifyConnectorsCredentials: VerifyConnectorsCredentialsObj;
}

export interface VerifyConnectorsCredentialsObj {
  verificationComplete: boolean;
  invalidCredentialsConnectors: string[];
}

export interface ValidateSingleConnectorInput {
  connectorID: string;
}

export interface EnableConnectorInput {
  connectorID: string;
}

export interface DisableConnectorInput {
  connectorID: string;
}

export interface ConnectorEnableDisableResponse {
  isConfigured: boolean;
  id: string;
}

export interface BulkConnectorEnableDisableInput {
  connectorIDs: string[];
  value: boolean;
  oxConnectorName: string;
}

export interface CredentialsBase {
  tokenExpirationDate?: string;
  credentialsType?: string;
  credentialsName?: string;
  credentialsId?: string;
  webhookUrl?: string;
}

export interface TokenCredentials extends CredentialsBase {
  token: string;
}

export interface UserPasswordCredentials extends CredentialsBase {
  name: string;
  password: string;
}

export interface UserPasswordAndTenantCredentials extends CredentialsBase {
  tenant: string;
  name: string;
  password: string;
}

export interface TokenAndUserCredentials extends CredentialsBase {
  name: string;
  token: string;
}

export interface TenantClientsubscriptionIdSecretCredentials
  extends CredentialsBase {
  tenantId: string;
  clientId: string;
  clientSecret: string;
  subscriptionId?: string;
}

export interface TenantIdClientIdClientSecretCredentials
  extends CredentialsBase {
  tenantId: string;
  clientId: string;
  clientSecret: string;
}

export interface AzureCloudCredentials extends CredentialsBase {
  tenantId: string;
  clientId: string;
  clientSecret: string;
}

export interface ServicePrincipalCredentials extends CredentialsBase {
  tenantId: string;
  clientId: string;
  clientSecret: string;
}

export interface TokenAndProjectIdCredentials extends CredentialsBase {
  projectId: string;
  token: string;
}

export interface ClientIdSecretApiUrlCredentials extends CredentialsBase {
  clientId: string;
  clientSecret: string;
  apiUrl: string;
}

export interface TokenOnlyCredentials extends CredentialsBase {
  password: string;
}

export interface ClientIdClientSecretCredentials extends CredentialsBase {
  clientId: string;
  clientSecret: string;
}

export interface AppIdAndTokenCredentials extends CredentialsBase {
  appId: string;
  token: string;
}

export interface AWSEKSCredentials extends CredentialsBase {}
export interface AksCredentials extends CredentialsBase {}

export interface APISecretAndAccessKeyCredentials extends CredentialsBase {
  apiAccessKey: string;
  apiSecretKey: string;
}

export interface OrganizationIdAndApiKeyCredentials extends CredentialsBase {
  name: string;
  password: string;
  organizationId: string;
  apiKey: string;
}

export interface ClientIdSecretKeyCredentials extends CredentialsBase {
  clientId: string;
  secretKey: string;
}

export interface AWSAssumeRoleCredentials extends CredentialsBase {
  awsRoleArn?: string;
  awsExternalId?: string;
}
export interface AWSAssumeRoleCredentialsOnprem
  extends AWSAssumeRoleCredentials {
  awsAccessKey?: string;
  awsAccessSecret?: string;
}
export interface CredentialError {
  message: string;
}

export interface GitHubAppCredentials extends CredentialsBase {
  installationId: number;
}

export interface BitbucketAppCredentials extends CredentialsBase {
  appKey: string;
  clientKey: string;
}

export interface WebhookCredentials extends CredentialsBase {
  webhookUrl: string;
}

export type Credential =
  | UserPasswordAndTenantCredentials
  | TokenCredentials
  | UserPasswordCredentials
  | TokenAndUserCredentials
  | TokenAndProjectIdCredentials
  | ClientIdSecretApiUrlCredentials
  | TokenOnlyCredentials
  | TenantClientsubscriptionIdSecretCredentials
  | APISecretAndAccessKeyCredentials
  | APISecretAndAccessKeyCredentials
  | ClientIdSecretKeyCredentials
  | OrganizationIdAndApiKeyCredentials
  | GitHubAppCredentials
  | BitbucketAppCredentials
  | AWSEKSCredentials
  | AzureCloudCredentials
  | ClientIdClientSecretCredentials
  | AppIdAndTokenCredentials
  | WebhookCredentials
  | AksCredentials
  | ServicePrincipalCredentials;

export type ConnectorFieldValue =
  | string
  | boolean
  | CredentialsType
  | Credential[]
  | undefined;

export enum ConnectorErrorMessages {
  EnableFailed = 'Enabling connector failed due to an error',
  DisableFailed = 'Disabling connector failed due to an error',
}

export interface ConnectorResponseObject {
  connector: Connector;
}

export interface SingleConnectorResponseObject {
  getConnector: Connector;
}

export interface GetConnectorResourcesInput {
  connectorID: string;
  resourceType?: string;
  credentialsId?: string;
}

export interface GetConnectorResourcesResponse {
  getConnectorResources: {
    resources: RepoNode[];
    monitorAllNewlyCreatedResources: boolean;
  };
}

export interface MonitorConnectorResourcesInput {
  connectorID: string;
  credentialsId?: string;
  resources: Nullable<Omit<RepoNode, 'children'>[]>;
  resourceCount?: number;
  monitorAllResources?: boolean;
  monitorAllNewlyCreatedResources?: number;
}

export interface MonitorConnectorResourcesResponse {
  monitorConnectorResources: {
    success: boolean;
  };
}

export interface ConnectorsByFamilyObject {
  family: Connector['family'];
  familyDisplayName: OneOfValues<typeof OxCategoriesNames>;
  connectors: Connector[];
}

export interface onboardingConnectorsObject {
  connectors: Connector[];
}

export interface onboardingConnectorsObjectResponse {
  connectors: ConnectorResponseObject[];
}

export interface ConnectorsByFamilyObjectResponse
  extends Omit<ConnectorsByFamilyObject, 'connectors'> {
  connectors: ConnectorResponseObject[];
}

export interface IdentityProviderInfo {
  baseURL: string;
  urlParams: string;
  scope: string;
  configText: string;
  user_scope?: string;
}

export interface GitHubAppInfo {
  baseURL: string;
  urlPath: string;
  configText: string;
}

export interface BitbucketAppInfo {
  baseURL: string;
  queryParameters: string[];
  configText: string;
}

export interface AWSCloudFormationInfo {
  baseURL: string;
  urlParams: string;
}

export interface ConnectorInstructionsType {
  type: string;
  title?: string;
  details?: string[];
  permissions?: string[];
  linksToDocs?: {
    href: string;
    title: string;
  }[];
}
export interface IdentityProviderInput {
  code: string;
  state: string;
  identityProviderBaseURL: string;
}

export interface GitHubAppInstallationInput {
  code: string;
  installationId: number;
  redirectUrlPathname: string;
}

export interface BitbucketAppInstallationInput {
  jwt: string;
  userUuid: string;
}

export interface IConnectorCredentialsFormProps {
  values: CredentialFormFields;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isConnectorConfigured: boolean;
  areCredentialsExpired: boolean;
  credentialsExpirationMsg: string;
  errorMessage?: string;
  credentialsType?: CredentialsType;
  connector: Connector;
  tenantFieldRequired: boolean;
  onpremAWSCredentialsRequired: boolean;
  tokenLabelField: string;
  isReadOnlyRole: boolean;
  disabled?: boolean;
}

export interface IConnectorHostURLProps {
  hasError: boolean;
  name: string;
  displayName: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isDisabled: boolean;
}

export interface ICredentialsFormSwitchProps {
  credentialsFormProps: IConnectorCredentialsFormProps;
  credentialsType: CredentialsType;
}

export interface IConnectorActionsGroupProps {
  onRemoveCredentials?: React.MouseEventHandler<HTMLButtonElement>;
  onVerifyAndAdd?: React.MouseEventHandler<HTMLButtonElement>;
  validateConnector?: () => void;
  openConnectorSettings: () => void;
  connectorValidationInProgress: boolean;
}
export interface IConnectorButtonGroupProps
  extends IConnectorActionsGroupProps,
    WithDisabledIfUnauthorizedProps {
  disabledConnect: boolean;
  isConnectorConfigured: boolean;
  showValidationResult: boolean;
  connectorIsValid: boolean;
  isScanning: boolean;
}

export enum ConnectorsIDPErrors {
  MissingCode = 'missingIdpCode',
  MissingState = 'missingIdpState',
  AccessDenied = 'idpAccessDenied',
  ValidationError = 'validationError',
  GeneralError = 'generalIdpError',
}

export enum ConnectorsGitHubAppErrors {
  MissingCode = 'missingCode',
  MissingInstallationId = 'missingInstallationId',
  InstallationRequest = 'installationRequest',
  UnhandledSetupAction = 'unhandledSetupAction',
  ValidationError = 'validationError',
}

export enum ConnectorsBitbucketAppErrors {
  MissingJWT = 'missingJWT',
  MissingUserUuid = 'missingUserUuid',
  ValidationError = 'validationError',
}

export enum GitHubAppSetupAction {
  request = 'request',
  install = 'install',
  update = 'update',
}

export interface GetAdditionalTabsSettingsInput {
  connectorID: string;
  type: string;
}

export enum ResourceType {
  Branch = 'Branch',
  Resources = 'Resources',
}

export interface ConnectorAdditionalTabsSettingsResponse {
  settings: AdditionalTabSetting[];
  values: {
    key: string;
    value: AdditionalTabSettingValue[];
  }[];
}

export interface AdditionalTabSetting {
  id: string;
  type: string;
  title: string;
  value: AdditionalTabSettingValue[]; // multiple values for autocomplete TODO: adjust to simple inputs
  options: AdditionalTabSettingValue[];
}

export interface AdditionalTabSettingValue {
  id: string;
  name: string;
  value: string;
}

export interface SaveConnectorAdditionalResourcesInput {
  connectorID: string;
  tab: string;
  values: {
    key: string;
    value: AdditionalTabSettingValue[];
  }[];
}

export interface GetConnectorAppBranchesInput {
  connectorID: string;
  appName: string;
  appId: string;
  resourceOrgName?: string;
}

export interface Branch {
  name: string;
  isMonitored: boolean;
  isDefault: boolean;
  appName: string;
  createdAt?: number;
  resourceOrgName?: string;
}

export interface GetConnectorAppBranchesResponse {
  getConnectorAppBranches: {
    branches: Branch[];
    total?: number;
    selected?: number;
  };
}

export interface AddAppBranchToMonitorInput {
  connectorID: string;
  branches: Pick<
    Branch,
    'name' | 'appName' | 'isMonitored' | 'resourceOrgName'
  >[];
}
