import { Nullable, SeverityAlert, AggregationItem } from 'ox-common-types';
import {
  DependencyEdge,
  DependencyNode,
  ScaVulnerability,
} from '../issues/common/types/issues-types';

export interface SbomLibraryItem {
  libraryName: string;
  id: string;
  references: SbomReference[];
  appType: string;
  libId: string;
  libraryVersion: string;
  license: string;
  appName: string;
  dependencyType: string;
  location: string;
  source: string;
  appId: string;
  locationLink: string;
  appLink: string;
  pkgName: string;
  copyWriteInfo: string[];
  copyWriteInfoLink: string;
  libLink: string;
  dependencyGraphNodes: DependencyNode[];
  dependencyGraphEdges: DependencyEdge[];
  vulnerabilityCounts: SeverityAlert;
  triggerPackage: string;
  extraInfo?: { key: string; value: string }[];
  dependencyGraph?: Nullable<{
    nodes: DependencyNode[];
    edges: DependencyEdge[];
    allNodes: DependencyNode[];
    allEdges: DependencyEdge[];
  }>;
  vulnerabilities?: ScaVulnerability[];
  language: string;
  latestVersion: string;
  latestVersionDate: string;
  maintainers: number;
  stars: number;
  forks: number;
  openIssues: number;
  homepage: string;
  notPopular: boolean;
  licenseIssue: boolean;
  notMaintained: boolean;
  isDeprecated: boolean;
  downloads: number;
  contributors: number;
  packageManager: string;
  packageManagerLink: string;
  licenseLink: string;
  sourceLink: string;
  notUpdated: boolean;
  notImported: boolean;
  dependencyLevel: number;
  artifactInSbomLibs: AggregationItem[];
  maintainersList: Maintainer[];
  sha: string;
}

export interface SbomReference {
  triggerPackage: string;
  location: string;
  fileName: string;
  locationLink: string;
  dependencyType: string;
  dependencyLevel: number;
  commit: {
    commitedAt: string;
    committerName: string;
    committerEmail: string;
  };
}

export interface Maintainer {
  name: string;
  email: string;
}

export enum DependencyTypes {
  Direct = 'Direct',
  Indirect = 'Indirect',
  Development = 'Development',
}

export enum SourceTypes {
  Registry = 'Registry',
  Repository = 'Repository',
}

export interface ArtifactInSbomLibs {
  image: string;
  imageLink: string;
  imageCreatedAt: string;
  sha: string;
  os: string;
  osVersion: string;
  baseImage: string;
  baseImageVersion: string;
  tag: string;
  layer: string;
  registryName: string;
}
