import sbomService from '../../../../../../../sbom/services';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { setErrorInnerTabs } from '../../store-actions/issue-drawer-tabs-store-actions';

export const loadDependencyGraph = async (
  appId: string,
  triggerPackage: string,
  library: string,
) => {
  const [trigger, fileName] = triggerPackage.split(' at ');
  setErrorInnerTabs('Dependency Graph', false);
  const response = await sbomService.getDependencyGraph.execute({
    appId,
    triggerPackage: trigger,
    fileName,
    library,
  });
  IssuesStore.triggerPackage = triggerPackage;
  if (response) {
    IssuesStore.dependencyGraph = response;
  } else {
    IssuesStore.dependencyGraph = null;
    setErrorInnerTabs('Dependency Graph', true);
  }
};
