import { AppPages } from 'app-navigator';
import { isDevelopment } from 'env-utils';
import { IconType, SeverityType } from 'ox-common-types';
import { ImTree } from 'react-icons/im';
import { PageIcons } from '../menu/config/main-menu-config';

export interface NoViolations {
  label: string;
  policyCount: number;
}
export interface AppsByCategoryViolation {
  label: string;
  policyCount: number;
  policyTotal: number;
  policyListTop: PolicyList[];
  appListTop: ApplicationTopListItem[];
  severity?: number;
  relevantApps: number;
}

export interface PolicyList {
  policyName: string;
  id: string;
  appCount: number;
  alertCount: number;
}

export interface ApplicationTopListItem {
  oxId: string | number;
  name: string;
  priority: number;
}
export interface Violations {
  label: string;
  policyCount: number;
  children: AppsByCategoryViolation[];
}
export interface PolicyBySeverity {
  noViolations: NoViolations;
  violations: Violations;
}
export interface OrgScanData {
  //score: number;
  scanDate: number;
  scanId: string;
  appsNotRelevant: number;
  appsRelevant: number;
  appsTotal: number;
  policyCount: number;
  systems: string[];
  policies: string[];
  severitiesAlerts: { severity: SeverityType; alerts: number }[];
  scannedApps: number;
  totalIssues: number;
  scanStartDate: string;
  scanFinishDate: string;
  scanType: string;
  scanInfoStats: string;
}

export interface OrgScanInfo {
  systemsTexts: string[];
  policiesTexts: string[];
  historical: { date: Date };
  totalScanRepos: number;
}
export interface OrgScanInfoBreakdown {
  apps?: number;
  activePolicies?: number;
  violations: {
    appox?: number;
    critical: number;
    high: number;
    medium: number;
    low?: number;
  };
  totalIssues?: number;
}

export interface LastScanDate {
  lastFormatScanDate: string;
  lastFormatScanTime: string;
  relativeDate: string;
}

export interface DiscoveredPolicies {
  enabled: number;
  disabled: number;
}

export interface DashboardResponse {
  getOrgScanInfo: OrgScanData;
}

export type ChartNodeNames = 'Critical' | 'High' | 'Medium';

export interface PbomBreakdownItem {
  name: string;
  icon: IconType;
  number: number;
  link: string;
  gqlKey: string | null;
  inputName: string | null;
}

export enum PbomBreakdownItemsNames {
  APPLICATIONS = 'Applications',
  PROTECTED_REPOS = 'Protected Repos',
  LIBRARIES = 'Libraries',
  APIs = 'APIs',
  ARTIFACTS = 'Artifacts',
  CLOUD_ASSETS = 'Cloud Assets',
  SAAS = 'Saas',
}

export const DASHBOARD_PBOM_BREAKDOWN_ITEMS_LIST: PbomBreakdownItem[] = [
  {
    name: PbomBreakdownItemsNames.APPLICATIONS,
    icon: PageIcons[AppPages.Applications] as IconType,
    number: 0,
    link: AppPages.Applications,
    gqlKey: 'getApplications',
    inputName: 'getApplicationsInput',
  },
  {
    name: PbomBreakdownItemsNames.PROTECTED_REPOS,
    icon: ImTree,
    number: 0,
    link: AppPages.Applications,
    gqlKey: null,
    inputName: null,
  },
  {
    name: PbomBreakdownItemsNames.LIBRARIES,
    icon: PageIcons[AppPages.Sbom] as IconType,
    number: 0,
    link: AppPages.Sbom,
    gqlKey: 'getSbomLibraries',
    inputName: 'getSbomLibrariesInput',
  },
  {
    name: PbomBreakdownItemsNames.APIs,
    icon: PageIcons[AppPages.ApiInventory] as IconType,
    number: 0,
    link: AppPages.ApiInventory,
    gqlKey: 'getApiSecurityFiltersLazy',
    inputName: 'getApiSecurityInput',
  },
  {
    name: PbomBreakdownItemsNames.ARTIFACTS,
    icon: PageIcons[AppPages.Artifacts] as IconType,
    number: 0,
    link: AppPages.Artifacts,
    gqlKey: 'getArtifacts',
    inputName: 'getArtifactsInput',
  },
  {
    name: PbomBreakdownItemsNames.SAAS,
    icon: PageIcons[AppPages.SaasBom] as IconType,
    number: 0,
    link: AppPages.SaasBom,
    gqlKey: 'getSaasBomFiltersLazy',
    inputName: 'getSaasBomItemsInput',
  },
];

if (isDevelopment()) {
  // The cloud bom is available only in dev
  DASHBOARD_PBOM_BREAKDOWN_ITEMS_LIST.splice(5, 0, {
    name: PbomBreakdownItemsNames.CLOUD_ASSETS,
    icon: PageIcons[AppPages.CloudBom] as IconType,
    number: 0,
    link: AppPages.CloudBom,
    gqlKey: 'getCloudItems',
    inputName: 'getCloudItemsInput',
  });
}

export const isNewDashboard = isDevelopment();
