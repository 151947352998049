import { Button, Theme, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, { Controls, Node, Panel, ReactFlowInstance } from 'reactflow';
import { DependencyEdge, DependencyNode } from '../../../types/issues-types';
import VulnerabilityNode from './VulnerabilityNode';
import { makeStyles } from 'tss-react/mui';
import { getFormattedData } from './utils';
import { ModalDialog } from 'ox-react-components';

const VulnerabilityGraph: FC<
  React.PropsWithChildren<VulnerabilityGraphProps>
> = ({ nodes, edges, selectedNode, children, nodeType, onNodeClick }) => {
  const theme = useTheme();
  const nodeTypes = useMemo(() => ({ special: VulnerabilityNode }), []);

  const { classes } = useStyles();
  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);

  const setInstance = (instance: ReactFlowInstance) => {
    reactFlowInstance.current = instance;
  };

  const { allNodes, allEdges } = useMemo(
    () => getFormattedData(nodes, edges, selectedNode, nodeType),
    [nodes, edges, selectedNode, nodeType],
  );

  const [fullMode, setFullMode] = useState(false);
  const toggleFullMode = useCallback(() => {
    setFullMode(prev => !prev);
  }, []);

  useEffect(() => {
    setTimeout(() => reactFlowInstance?.current?.fitView(), 100);
  }, [allNodes, allEdges]);

  const Graph = (
    <ReactFlow
      fitView
      onNodeClick={onNodeClick}
      onlyRenderVisibleElements
      className={classes.content}
      onInit={setInstance}
      style={{
        backgroundColor:
          theme.palette.mode === 'light'
            ? '#e5e5e5'
            : theme.palette.background.default,
      }}
      maxZoom={1}
      minZoom={0.1}
      nodeTypes={nodeTypes}
      proOptions={{ hideAttribution: true }}
      nodes={allNodes}
      edges={allEdges}>
      <Controls />
      <Panel position='top-right'>
        <Button variant='text' onClick={toggleFullMode}>
          {fullMode ? 'Close ' : ''} Full Screen
        </Button>
      </Panel>
      {children}
    </ReactFlow>
  );
  return (
    <>
      {Graph}
      <ModalDialog
        hideHeader
        onClose={toggleFullMode}
        contentClassName={classes.dialogContainer}
        sx={{ padding: 0, margin: 0 }}
        fullScreen
        open={fullMode}>
        {Graph}
      </ModalDialog>
    </>
  );
};

export interface VulnerabilityGraphProps {
  nodes: DependencyNode[];
  edges: DependencyEdge[];
  nodeType?: 'tb' | 'lr';
  selectedNode?: string;
  onNodeClick?: (_, node: Node<DependencyNode>) => void;
  triggers?: {
    fileName: string;
    triggerPackage: string;
  }[];
  onTriggerChange?: (triggerPackage: string) => void;
  selectedTrigger?: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    height: '100%',
  },
  dialogContainer: {
    padding: 0,
    margin: 0,
  },
}));

export default VulnerabilityGraph;
