import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import query from './get-application-flow.gql';
import { GetSingleApplicationInput } from '../get-single-application/get-single-application-service';
import { ApplicationFlow } from 'ox-react-components';

const getApplicationFlow = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      getSingleApplicationInput: GetSingleApplicationInput,
      cache = true,
    ) => {
      try {
        const results = await client.query<{
          getSingleApplicationInfo: {
            applicationFlows: ApplicationFlow;
          };
        }>({
          query,
          variables: { getSingleApplicationInput },
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });

        return results.data.getSingleApplicationInfo;
      } catch (error) {
        logger.error(
          'Failed to fetch application flow',
          getSingleApplicationInput,
          error,
        );
        return null;
      }
    },
  };
};

export default getApplicationFlow;
