import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  HttpOptions,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry/index.js';
import { isLocalDevelopment } from 'env-utils';
import { authLink } from './auth-link';
import { errorLink } from './error-link';

// Usage: createApiClient(resolveServiceUrl('CONNECTORS')) => ApolloClient<NormalizedCacheObject>
export const createApiClient = ({
  uri,
}: createApiClientOptions): ApolloClient<NormalizedCacheObject> => {
  const httpLinkOptions: HttpOptions = {
    uri,
    credentials: isLocalDevelopment() ? 'omit' : 'include',
  };

  const httpLink = createHttpLink(httpLinkOptions);
  const retryLink = new RetryLink({
    delay: {
      initial: 500,
      max: 10000,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error,
    },
  });

  // This signalLink is used to pass the signal to the request so that we can abort the request
  const signalLink = new ApolloLink(
    (operation: Operation, forward: NextLink) => {
      const { signal } = operation.getContext();
      operation.setContext(
        ({ fetchOptions = {} }: { fetchOptions: Record<string, unknown> }) => ({
          fetchOptions: {
            ...fetchOptions,
            signal,
          },
        }),
      );
      return forward(operation);
    },
  );

  return new ApolloClient({
    cache: new InMemoryCache({ addTypename: false }),
    link: from([authLink, retryLink, errorLink, signalLink, httpLink]),
  });
};

type createApiClientOptions = Pick<HttpOptions, 'uri'>;
