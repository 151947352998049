import { PbomBreakdownItem, OrgScanData } from '../dashboard-types';
import DashboardStore from '../stores/dashboard-store';

export const clearStore = () => {
  DashboardStore.issuesScore = undefined;
  DashboardStore.supplyChainItems = [];
  DashboardStore.orgScanData = null;
};

export const setOrgScaneData = (orgScanData: OrgScanData) => {
  DashboardStore.orgScanData = orgScanData;
};

export const setPreventCategories = (categories: string[]) => {
  DashboardStore.preventCategories = categories;
};

export const setLoadingPreventCategories = (loading: boolean) => {
  DashboardStore.loadingPreventCategories = loading;
};

export const setPbomBreakdownItems = (
  pbomBreakdownItems: PbomBreakdownItem[],
) => {
  DashboardStore.pbomBreakdownItems = pbomBreakdownItems;
};

export const setIsLoadingPbomBreakdown = (isLoading: boolean) => {
  DashboardStore.isLoadingPbomBreakdown = isLoading;
};

export const setIsErrorLoadingPbomBreakdown = (isError: boolean) => {
  DashboardStore.isErrorLoadingPbomBreakdown = isError;
};
