import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import {
  Branch,
  BranchesPerConnector,
  ResourceAppItem,
} from '../../../connectors-types';
const baseStore = createStore<MultiBranchSelectionStoreState>(
  {
    selectedApp: null,
    searchValue: '',
    isLoadingSave: false,
    isLoadingBranches: false,
    branchesToScanPerConnector: {},
    branchesToScanModalState: [],
    isErrorLoadingBranches: false,
  },
  'Multi Branch Selection Store',
);

export const MultiBranchSelectionStore = derive(
  {
    filteredBranchesToScan: (get): Branch[] => {
      const { branchesToScanModalState, searchValue } = get(baseStore);
      const searchValueLower = searchValue.toLowerCase();
      return (
        branchesToScanModalState?.filter(branch => {
          if (searchValueLower) {
            return (
              branch.name.toLowerCase().includes(searchValueLower) ||
              branch.isMonitored
            );
          } else {
            return true;
          }
        }) || []
      );
    },
    selectedBranches: (get): Branch[] => {
      const { branchesToScanModalState } = get(baseStore);

      return (
        branchesToScanModalState?.filter(branch => branch.isMonitored) || []
      );
    },
  },
  { proxy: baseStore },
);

interface MultiBranchSelectionStoreState {
  selectedApp: Nullable<ResourceAppItem>;
  searchValue: string;
  branchesToScanPerConnector: BranchesPerConnector;
  isLoadingSave: boolean;
  isLoadingBranches: boolean;
  isErrorLoadingBranches: boolean;
  branchesToScanModalState: Branch[];
}
