import { CategoryDisplayName } from '@oxappsec/ox-consolidated-categories';
import {
  OxExclusionId,
  OxExclusionLabel,
  OxExclusionLevel,
  OxExclusionType,
} from '@oxappsec/exclusion-service';
import {
  AggregationItem,
  IconType,
  Nullable,
  RepoTypes,
  SeverityType,
  TicketRef,
} from 'ox-common-types';
import {
  ConditionalFilter,
  FilterIssueType,
  FilterLabel,
  LazyFiltersResponse,
  SearchInput,
} from 'ox-filter-utils';
import { ApplicationFlow } from 'ox-react-components';
import { PullRequest } from '../../../quick-fix/types/quick-fix-types';
import { SbomLibraryItem } from '../../../sbom/sbom-types';
import { RawIssue, RawIssueApp, RawIssuePolicy } from './raw-issue-type';
import { DocumentNode } from '@apollo/client';
import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';
import { CveSeverity } from 'ox-react-components/src/Vulnerabilities/icon-utils';
import { SeverityEvidenceItem } from 'ox-react-components/src/SeverityEvidence/types';
import { MessagingVendorTypes } from '../../../messaging-module/types/messaging-types';

export enum IssueSortKey {
  Category = 'Category',
  IssueName = 'IssueName',
  RepoName = 'RepoName',
  Owner = 'Owner',
  OpenDate = 'OpenDate',
  Occurrences = 'Occurrences',
  ResolvedDate = 'ResolvedDate',
  Severity = 'Severity',
  JobTriggeredAt = 'JobTriggeredAt',
  JobTriggeredBy = 'JobTriggeredBy',
  CicdIssueStatus = 'CicdIssueStatus',
  Enforcement = 'Enforcement',
  JobId = 'JobId',
  ResolvedReason = 'ResolvedReason',
  DisappearedDate = 'DisappearedDate',
  DisappearedReason = 'DisappearedReason',
}

export interface ComplianceItem {
  standard: string;
  control: string;
  description: string;
  category: string;
  standardLink?: string;
  categoryLink?: string;
  controlLink?: string;
}

export enum SeverityDiffStatus {
  New = 'new',
  Removed = 'removed',
  Increased = 'increased',
  Decreased = 'decreased',
}

export interface LatestCommit {
  date: string;
  commitLink: string;
  match: string;
  snippet: string;
  snippetLineNumber: number;
}

export interface IssueDetails {
  importantSeverityBreakdown: string[];
  highestOXCVESeverity: string;
  isCVERelated: boolean;
  latestCommit: LatestCommit;
  previousSeverity: {
    severity: string;
    severityChangedDate: string;
  };
  severityFactorsDiff: {
    shortName: string;
    change: number;
    status: SeverityDiffStatus;
  }[];
  correlatedIssueId: string;
  correlatedRegistry: string;
  additionalTabs: { type: string; aggItems: AggregationItem[] }[];
  compliance: ComplianceItem[];
  dependencyGraph?: Nullable<{
    nodes: DependencyNode[];
    edges: DependencyEdge[];
    allNodes: DependencyNode[];
    allEdges: DependencyEdge[];
  }>;
  oscarData: {
    id: string;
  }[];
  sbom: SbomLibraryItem;
  id: string;
  issueId: string;
  name: string;
  mainTitle: string;
  impact: string;
  secondTitle: string;
  secondTitleMarkup: string;
  severity: SeverityType;
  owners: string[];
  connector: string;
  learnMore: string[];
  app: RawIssueApp;
  policy: RawIssuePolicy;
  exclusions: Exclusions[];
  recommendation: string;
  recommendationMarkup: string;
  violationInfoTitle: string;
  sourceTools: string[];
  isPRAvailable: boolean;
  isFixAvailable: boolean;
  scaFixType: ScaFixType;
  autoFix: Nullable<FixIssue>;
  originalSeverity: number;
  overrideSeverity: boolean;
  overrideSeverityReason: string;
  aggregations: {
    type: string;
    items: AggregationItem[];
  };
  ruleId: string;
  exclusionCategory: string;
  resource: {
    id: string;
    type: string;
  };
  cwe: string[];
  extraInfo: [{ key: string; value: string }];
  applicationFlow: ApplicationFlow;
  fixLink: string;
  cweList: CweList[];
  category: CategoryDeatils;
  exclusionIssueComment: string;
  falsePostiveIssueComment: string;
  cancelFalsePositiveIssueComment: string;
  falseResolvedIssueComment?: Nullable<string>;
  isFalsePositive: boolean;
  isCanceledFalsePositive: boolean;
  cancelFalsePositiveComment?: string;
  falsePositiveComment?: string;
  exclusionAppComment: string;
  exclusionPolicyComment: string;
  dependencyChain: string[];
  publicExploitLink: string;
  fixIssue: Nullable<FixIssueDetails>;
  lowerSeverityReason: string[];
  indirectSupported: boolean;
  scaVulnerabilities: ScaVulnerability[];
  severityChangedReason: SeverityChangedReason[];
  originalToolSeverity: SeverityType;
  cicdFields?: CiCdFields;
  severityBreakdown: SeverityBreakdownItem[];
  occurrences: number;
  created: Date;
  createdAt: Date;
  comment: Nullable<string>;
  tickets: JiraTicketRef[];
  excludedByAlert: boolean;
  exclusionComment: string;
  exclusionExpiredAt: Date;
  fixes: PolicyFix;
  snoozeExpiredDate?: Date;
  exclusionId: string;
  isMonoRepoChild: boolean;
  originBranchName: string;
  monoRepoParent: string;
  scaTriggerPkg: string;
  scaTriggerPkgs: {
    scaTriggerPkg: string;
    fileName: string;
  }[];
  prDeatils: Nullable<PullRequest>;
  gptInfo: {
    gptResponse: string;
    user: string;
    createdAt: Date | string;
  };
  isGPTFixAvailable: boolean;
  newSeverity: string;
  exposedByApiItems: Nullable<{ apiId: string }[]>;
  problematicPkg: string;
}

export enum ScaFixType {
  UNKNOWN = 'Unknown',
  UNAVAILABLE = 'UNAVAILABLE',
  MAJOR = 'Major Upgrade',
  MINOR = 'Minor Upgrade',
  PATCH = 'Patch',
}

export interface SlackNotification {
  channelName: string;
  timestamp: string;
}
export interface SeverityBreakdownItem {
  trend: SeverityTrend;
  itemsCount: number;
  name: string;
  description: string;
  changeNumber: number;
  changeCategory: string;
  extraInfo: SeverityChangedExtraInfo[];
  withoutAutoNumbering?: boolean;
  evidenceLabel?: string;
}

export enum SeverityTrend {
  Up = 'up',
  Down = 'down',
  Neutral = 'neutral',
}

export interface SeverityChangedReason {
  changeNumber: number;
  reason: string;
  shortName: string;
  changeCategory: string;
  withoutAutoNumbering?: boolean;
  evidenceLabel?: string;
  extraInfo: SeverityChangedExtraInfo[];
  extraInfoContainer: ExtraInfoContainer[];
}

export interface SeverityChangedExtraInfo {
  key: string;
  value?: string;
  link: string;
  snippet?: SnippetInfo;
}

export interface ExtraInfoContainer {
  layerSha: string;
  layerNum: number;
  artifactName: string;
  sha: string;
  registryName: string;
}

export interface SnippetInfo {
  snippetLineNumber: number;
  language: string;
  text: string;
  fileName: string;
}

export interface DependencyNode {
  position?: { x: number; y: number };
  id: string;
  name?: string;
  vulnerable: boolean;
  issues: {
    appox: number;
    critical: number;
    high: number;
    medium: number;
    low: number;
  };
}

export interface DependencyEdge {
  v: string;
  w: string;
}

export interface CveSeverityFactor {
  sfName: CveSeverity;
  sfDescription?: string;
  sfChangedNumber: number;
  withoutAutoNumbering?: boolean;
  evidenceLabel?: string;
  evidences: SeverityEvidenceItem[];
}
export interface ScaVulnerability {
  cwe: { url: string; name: string; shortName: string }[];
  cveSeverityFactor: CveSeverityFactor[];
  oxSeverity: number;
  severityNumberFromTool: string;
  severityFromTool: string;
  cve: string;
  libName: string;
  dependencyChain: string;
  libVersion: string;
  chainDepth: number;
  cveLink: string;
  cvsVer: string;
  exploitInTheWildLink: string;
  description: string;
  dateDiscovered: string;
  minorVerWithFix: string;
  majorVerWithFix: string;
  exploitRequirement: string;
  exploitCode: ExploitCode;
  originalSeverity: string;
  issueId?: string;
}

export enum ExploitCode {
  Local = 'LOCAL',
  Network = 'NETWORK',
}
export interface FixIssue {
  fixType: FixType;
  activeFix: Nullable<ActiveFix>;
  fixTitle: string;
  fixDescription: string;
  isFixApplied?: boolean;
  fixAppliedBy: string;
  sourceControlType?: string;
  fixInput?: Input[];
  fixDate?: Date;
}

export interface Input {
  type: string;
  name: string;
  options: InputOption[];
}

export interface InputOption {
  name: string;
  metadata: string;
}

export interface FixIssueDetails extends FixIssue {
  fixPR: FixPR;
}

export interface ActiveFix {
  fixId: string;
  fixURL: string;
}

export interface FixPR {
  issueBranch: string;
  commitMessage: string;
  fixFiles: FixFile[];
}

export interface FixFile {
  filePath: string;
  newFileContent: string;
}

export enum FixType {
  PullRequest = 'pullRequest',
  GitPosture = 'GitPosture',
}

export interface CweList {
  name: string;
  description: string;
  url: string;
}

export interface RepoInfo {
  businessPriority: number;
  scores?: ScoreItem[];
}

export interface PolicyInfo {
  info: string;
  severity: SeverityType;
  careReason: string;
}

export interface Issue {
  policy: RawIssuePolicy;
  exclusionCategory: string;
  exclusionId: string;
  correlatedRegistry?: string;
  id: string;
  index: number;
  issueId: string;
  category: CategoryDisplayName;
  categoryId: string;
  description: string;
  mainTitle: string;
  secondTitle: string;
  isPRAvailable: boolean;
  autoFix: Nullable<FixIssue>;
  isFixAvailable: boolean;
  prDeatils: Nullable<PullRequest>;
  repo: {
    type: RepoTypes;
    name: string;
  };
  fix: PolicyFix;
  owner: string[];
  createdDate: Date;
  scanCreatedDate: Date;
  occurrences: number;
  exclusions: Exclusions[];
  app: RawIssueApp;
  fixIssue: Nullable<FixIssue>;
  jiraTickets: JiraTicketRef[];
  resolvedIssueDate?: Date;
  cicdFields?: CiCdFields;
  severity: SeverityType;
  comment: string;
  slackNotification?: SlackNotification[];
  excludedByAlert: boolean;
  exclusionComment: string;
  exclusionExpiredAt: Date;
  fixes: PolicyFix;
  policyId: string;
  gptInfo: {
    gptResponse: string;
    user: string;
    createdAt: Date;
  };
  isGPTFixAvailable: boolean;
  originalSeverity: number;
  overrideSeverity: boolean;
  isFalsePositive?: boolean;
  falsePositiveComment?: string;
  isCanceledFalsePositive: boolean;
  cancelFalsePositiveComment?: string;
  extraInfo: { key: string; value: string }[];
  ruleId: string;
  originalToolSeverity: string;
  tickets: TicketRef[];
  resolvedReason?: string;
  disappearedReasonDetails?: ResolvedDisappearedReasonDetails;
  disappearedReason?: string;
  resolvedReasonDetails?: ResolvedDisappearedReasonDetails;
  issueDetailsHeaders: IssueDrawerTabsHeader[];
  messages: IssueMessage[];
  disappearedDate?: number;
}

export interface ResolvedDisappearedReasonDetails {
  description: string;
  evidences: {
    title: string;
    items: {
      link: string;
      label: string;
    }[];
  }[];
}

export interface IssueMessage {
  messagingVendor: MessagingVendorTypes;
  recipients: {
    id: string;
    type: string;
    name: string;
  }[];
  createdAt: Date;
}

export type JiraTicketRef = TicketRef;

export interface FetchIssuesInput {
  scanID?: string;
  offset?: number;
  limit?: number;
  filters?: Nullable<{}>;
  sort?: {
    fields: Nullable<IssueSortKey>[];
    order: string[];
  };
  updateFilter?: boolean;
  owners?: string[];
  tagIds?: string[];
  inventoryFilters?: string[];
  dateRange?: {};
  search?: SearchInput[];
  topOffset?: number;
  scrollDirection?: string;
  issueId?: string;
  openItems?: string[];
  conditionalFilters?: ConditionalFilter[];
}

export interface FetchIssuesConditionalFiltersResponse {
  getIssuesConditionalFiltersLazy: LazyFiltersResponse;
}

export interface FetchIssuesFiltersLazyResponse {
  getIssuesFiltersLazyV2: LazyFiltersResponse;
}

export interface FetchIssuesFilterLabelsResponse {
  getIssuesFilterLabels: FilterLabel[];
}

export interface FetchIssueInput {
  issueId: string;
}

export interface FetchIssueDetailsByQueryParams {
  issueId: string;
  query: DocumentNode;
}

export interface FetchIssuesResponse {
  getIssues: FetchIssuesData;
  getSingleIssueInfo: IssueDetails;
  getIssuesFilters: FilterIssueType;
}

export interface FetchResolvedIssuesResponse {
  getResolvedIssues: FetchIssuesData;
}
export interface FetchResolvedSingleIssueResponse {
  getResolvedIssue: IssueDetails;
}

export interface FetchPipelineIssuesResponse {
  getCICDIssues: FetchIssuesData;
}

export interface CiCdFields {
  issueStatus: string;
  jobId: string;
  jobUrl: string;
  jobTriggeredAt: Date;
  jobTriggeredBy: string;
  jobTriggeredReason: string;
  sourceBranch: string;
  targetBranch: string;
  pullRequestId: string;
  pullRequestUrl: string;
  enforcement: Enforcement;
}

export enum Enforcement {
  Monitor = 'Monitor',
  Block = 'Block',
  Discovered = 'Discovered',
}
export interface FetchPipelineSingleIssueResponse {
  getCICDIssue: IssueDetails;
}

export interface FetchResolvedIssuesFiltersResponse {
  getResolvedIssuesFilters: FilterIssueType;
}

export interface FetchPipelineIssuesFiltersResponse {
  getCICDIssuesFilters: FilterIssueType;
}

export interface PipelineIssuesLazyFiltersResponse {
  getCICDIssuesConditionalFilters: LazyFiltersResponse;
}

export interface FetchIssuesData {
  issues: RawIssue[];
  totalIssues: number;
  offset: number;
  totalFilteredIssues: number;
  totalResolvedIssues: number;
  totalActiveIssues: number;
  topOffset: number;
  selectedPosition: number;
}

export interface ScoreItem {
  severityType: SeverityType;
  value: number | string;
  content?: string;
}

export interface AdditionalInfo {
  exploitWild: boolean;
  exploitType: string;
}

export interface Exclusions {
  label: OxExclusionLabel;
  type: OxExclusionType;
  id: string;
  isDefault: boolean;
  exclusionScope?: string;
  ffKey?: string;
  oxRuleId: OxExclusionId;
  level: OxExclusionLevel;
}

export interface learnMoreLink {
  name: string;
  href: string;
}

// Perhaps we can define it without the indexer
export interface AppFlowItemTooltipsProp {
  [key: string]: {
    title: string;
    content: string[];
    link?: string;
    icon?: IconType;
  };
}

export enum IssuesOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface FilterByTypesToolTip {
  category: string;
  value: string;
}

export interface DisablePolicyStatus {
  success: boolean;
  error: boolean;
}

export interface ExclusionItem {
  label: string;
  type: string;
  id: string;
  isDefault: boolean;
  scope: string;
}

export interface CategoryDeatils {
  name: string;
  categoryId: string;
}

export enum ExtraInfoTypes {
  OriginalSeverity = 'Original Severity',
  LowerSeverityReason = 'Lower Severity Reason',
  RecommendedVideo = 'Recommended Video',
}

export type LoadIssuesParams = {
  update?: boolean;
  cache?: boolean;
  limit?: number;
  issueId?: string;
  exclude?: boolean;
  scrollDirection?: string;
  search?: string;
};

export type LoadIssuesFiltersParams = {
  cache?: boolean;
  limit?: number;
  search?: SearchInput[];
  offset?: number;
};

export interface CommentToIssueResponse {
  isUpdated: boolean;
}

export interface DeleteCommentIssueInput {
  issueId: string;
}

export interface AddCommentToIssueInput {
  issueId: string;
  comment: Nullable<string>;
}

export interface IssueAction {
  actionId: string;
  tooltipText: string;
  icon: Nullable<IconType>;
  onClick: (...rest) => void;
  disabled?: boolean;
  hideAction?: boolean;
  highlight: boolean;
  title: string;
  subMenuActions?: SubMenuIssueAction[];
  relevantIssuePages: IssuePages[];
  // TODO: will need to remove this implementation once we find a better way to handle this
  onClickFunctionParams?: unknown[];
  permissions: {
    permissionScope: PermissionScope;
    permissionType: PermissionTypes;
  }[];
}

interface SubMenuIssueAction extends Omit<IssueAction, 'icon'> {
  icon: Nullable<IconType>;
}

export interface BulkIssuesAction {
  text: string;
  icon: IconType;
  onClick: () => void;
  disabled?: boolean;
  permissions: {
    permissionScope: PermissionScope;
    permissionType: PermissionTypes;
  }[];
}

export interface PolicyFixInput {
  type: string;
  name: string;
  displayName?: string;
  minSelect?: number;
  maxSelect?: number;
  options: PolicyFixInputOption[];
  multiSelect?: boolean;
}
export interface PolicyFixInputOption {
  name: string;
  displayName?: string;
  selected?: boolean;
  metadata: string;
  info?: string;
  isDisabled?: boolean;
}
export interface PolicyFix {
  settingType: string;
  tooltip: string;
  description: string;
  warning: string;
  confirmation: string;
  inputs: PolicyFixInput[];
}

export interface SeverityByCategory {
  changeCategory: string;
  severities: SeverityBreakdownItem[];
}

export enum SeverityChangeFactorTypes {
  Reachability = 'Reachable',
  Exportability = 'Exploitable',
  Damage = 'Damage',
}

export interface IssueExportsOptions {
  includeIssuesFilters: boolean;
  flattenAgg?: boolean;
  exportFileType: 'pdf' | 'csv';
}

export enum AutoFixTypes {
  setRepoToPrivate = 'setRepoToPrivate',
  archiveRepo = 'archiveRepo',
  setRepoBranchProtectionUnReviewedCode = 'setRepoBranchProtectionUnReviewedCode',
  setRepoBranchProtectionForDeletionOnBranch = 'setRepoBranchProtectionForDeletionOnBranch',
  setRepoBranchProtectionForAddingSignedCommits = 'setRepoBranchProtectionForAddingSignedCommits',
  changeRepoCollaboratorStatus = 'changeRepoCollaboratorStatus',
  changeOrgUserStatus = 'changeOrgUserStatus',
}

export interface UpdateIssueSeverityInput {
  issueId: string;
  severity: number;
}

export interface BulkUpdateIssuesSeverityInput {
  issueIds: string[];
  severity: number;
}

export type BulkIssuesCommentActionType = 'Delete' | 'Edit' | 'Add';

export interface BulkIssuesCommentInput {
  issuesId: string[];
  comment?: string;
  action: BulkIssuesCommentActionType;
}
export interface BulkResetIssuesSeverityInput {
  issueIds: string[];
}

export enum ResetStatus {
  IssueNotFound,
  ResetSuccess,
  ResetFailed,
  NothingToReset,
}

export type IssueSeverityReset = {
  issueId: string;
  resetStatus: ResetStatus;
};

export interface ResetIssuesSeverityResponse {
  issueSeverityReset: IssueSeverityReset[];
}

export interface ResetIssueSeverityInput {
  issueId: string;
}

export interface IssueSeverityResponse {
  updateIssueSeverity?: boolean;
  resetIssueSeverity?: boolean;
}

export const SeverityMappingToName = (sev: number) => {
  let sevType = '';
  switch (sev) {
    case 0:
      sevType = 'Info';
      break;
    case 1:
      sevType = 'Low';
      break;
    case 2:
      sevType = 'Medium';
      break;
    case 3:
      sevType = 'High';
      break;
    case 4:
      sevType = 'Critical';
      break;
    case 5:
      sevType = 'Appoxalypse';
      break;
    default:
      sevType = '';
      break;
  }
  return sevType;
};

export const SeverityMappingToNumber = (sev: string) => {
  let sevType = 0;
  switch (sev) {
    case 'Info':
      sevType = 0;
      break;
    case 'Low':
      sevType = 1;
      break;
    case 'Medium':
      sevType = 2;
      break;
    case 'High':
      sevType = 3;
      break;
    case 'Critical':
      sevType = 4;
      break;
    case 'Appoxalypse':
      sevType = 5;
      break;
    default:
      sevType = 0;
      break;
  }
  return sevType;
};

export enum SubMenuIssuesActions {
  JiraOptions = 'JiraOptions',
  AzureBoardsOptions = 'AzureBoards',
  ExcludeOptions = 'ExcludeOptions',
}

export type SelectedIssuesAggsItemsRes = Pick<
  IssueDetails,
  'issueId' | 'aggregations'
>;

export interface IssueDrawerHeaderInput {
  issueId: string;
  page: IssuePages;
}

export enum IssuePages {
  CurrentIssues = 'CurrentIssues',
  ResolvedIssues = 'ResolvedIssues',
  PipelineIssues = 'PipelineIssues',
  RemovedIssues = 'RemovedIssues',
  PolicyWorkflows = 'PolicyWorkflows',
}

export interface IssueDrawerTabsHeader {
  id: string;
  label: string;
  featureFlag?: string;
}

export interface IssueDrawerTabProps {
  setIsLoading: (isLoading: boolean) => void;
  setIsError: (isError: boolean) => void;
  isLoading: boolean;
  isError: boolean;
  tabId: string;
  moveToTab?: (tabName: string) => void;
}

export const MAX_SELECTED_ISSUES = 50;

export interface CancelReportFPInput {
  issueId: string;
  comment: string;
}

export interface VulnerabilitiesResponse {
  vulnerabilities: ScaVulnerability[];
  offset: number;
  total: number;
  severityFactorBreakdown: { sfName: string; count: number }[];
}

export interface GetVulnerabiltiesInput {
  issueId: string;
  offset: number;
  tab: string;
  search: string;
  cveSeverityFactors: string[];
  orderField?: string;
  orderDirection?: string;
}
