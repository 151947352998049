import { Nullable } from 'ox-common-types';
import { FilterItem } from 'ox-filter-utils';
import ExclusionsStore from '../stores/exclusions-store';
import { Exclusion } from '../types/exclusion-types';
import { snapshot } from 'valtio';
import { getAllTableRowsIdsBetweenSelection } from 'common-utils';

export const setExclusions = (
  exclusions: Exclusion[],
  getMoreItems?: boolean,
) => {
  if (getMoreItems) {
    ExclusionsStore.exclusions?.push(...exclusions);
  } else {
    ExclusionsStore.exclusions = exclusions;
  }
};

export const setSelectedExclusion = (exclusion: Nullable<Exclusion>) => {
  ExclusionsStore.selectedExclusion = exclusion;
};

export const setExclusionsError = (isError: boolean, errorMessage: string) => {
  ExclusionsStore.generalError = isError;
  ExclusionsStore.errorMessage = errorMessage;
};

export const setModalAction = (isShowing: boolean): void => {
  ExclusionsStore.showModal = isShowing;
};

export const setIsDisablePolicyLoading = (isDisablePolicyLoading: boolean) => {
  ExclusionsStore.isDisablePolicyLoading = isDisablePolicyLoading;
};

export const setIsApplicationIrrelevantLoading = (
  isDisableApplicationLoading: boolean,
) => {
  ExclusionsStore.isIrrelevantApplicationLoading = isDisableApplicationLoading;
};

export const setIsExcludeAlertLoading = (isExcludeAlertLoading: boolean) => {
  ExclusionsStore.isExcludeAlertLoading = isExcludeAlertLoading;
};

export const setBulkExcludeAlertLoading = (
  isBulkExcludeAlertLoading: boolean,
) => {
  ExclusionsStore.isBulkExcludeAlertLoading = isBulkExcludeAlertLoading;
};

export const setIsRemoveExclusionLoading = (
  isRemoveExclusionLoading: boolean,
) => {
  ExclusionsStore.isRemoveExclusionLoading = isRemoveExclusionLoading;
};

export const setIsUpdateExpiredAtByIdLoading = (
  isUpdateExpiredAtById: boolean,
) => {
  ExclusionsStore.isUpdateExpiredAtById = isUpdateExpiredAtById;
};

export const setModalUpdateExpiredAt = (isShowing: boolean): void => {
  ExclusionsStore.updateExpiredAtModal = isShowing;
};

export const setModalUpdateExpiredAtBanner = (banner: boolean): void => {
  ExclusionsStore.updateExpiredAtModalBanner = banner;
};

export const setIsLoadingRemoveSnooze = (isLoading: boolean) => {
  ExclusionsStore.removeSnoozeLoading = isLoading;
};

export const setModalRemoveSnooze = (isShowing: boolean): void => {
  ExclusionsStore.removeSnoozeModal = isShowing;
};

export const setTotalExclusionsNumber = (totalExclusions: number) => {
  ExclusionsStore.totalExclusions = totalExclusions;
};

export const setTotalExclusionsFiltersNumber = (
  totalExclusionsFilters: number,
) => {
  ExclusionsStore.totalFilteredExclusions = totalExclusionsFilters;
};

export const setCurrentSnoozeDateValue = (expiredAt: Nullable<Date>) => {
  ExclusionsStore.snoozeModalCurrentSnoozeDateValue = expiredAt;
};

export const setExpireDate = (expiredAt?: Nullable<Date>) => {
  if (ExclusionsStore.selectedExclusion) {
    ExclusionsStore.selectedExclusion.expiredAt = expiredAt;
  }
};

export const setOffsetInStore = (offset: number) => {
  ExclusionsStore.offset = offset;
};

export const setIsSnoozeBannerSnoozeOpen = (isOpen: boolean) => {
  ExclusionsStore.isSnoozeBannerSnoozeOpen = isOpen;
};

export const setNearlyExpiredExclusions = (
  nearlyExpiredExclusions: Exclusion[],
) => {
  ExclusionsStore.nearlyExpiredExclusions = nearlyExpiredExclusions;
};

export const setTotalNearlyExpiredExclusions = (
  totalNearlyExpiredExclusions: number,
) => {
  ExclusionsStore.totalNearlyExpiredExclusions = totalNearlyExpiredExclusions;
};

export const setExclusionsFiltersLazy = (exclusionsFilters: FilterItem[]) => {
  ExclusionsStore.lazyFilters = exclusionsFilters;
};

export const setSearchValues = (searchValue: string, type: string) => {
  ExclusionsStore.searchValues[type] = searchValue;
};

export const setSelectedExclusions = (selectedExclusions: string[]) => {
  ExclusionsStore.selectedExclusionsIds = selectedExclusions;
};

export const setHoveredRowExclusionId = (
  hoveredRowExclusionId: Nullable<string>,
) => {
  ExclusionsStore.hoveredRowExclusionId = hoveredRowExclusionId;
};

export const selectExclusion = (
  exclusionId: string,
  isShiftPressed: boolean,
) => {
  const { selectedExclusionsIds: selected, exclusions } =
    snapshot(ExclusionsStore);

  if (isShiftPressed && exclusions) {
    const selectedUpdatedIds = getAllTableRowsIdsBetweenSelection(
      exclusionId,
      'exclusionId',
      exclusions,
      selected,
    );

    ExclusionsStore.selectedExclusionsIds = selectedUpdatedIds;
  } else {
    if (selected.includes(exclusionId)) {
      const selectedUpdated = selected.filter(
        (selectedId: string) => selectedId !== exclusionId,
      );
      ExclusionsStore.selectedExclusionsIds = selectedUpdated;
    } else {
      ExclusionsStore.selectedExclusionsIds = [...selected, exclusionId];
    }
  }
};
