import { OxExclusionId, OxExclusionScope } from '@oxappsec/exclusion-service';
import { createStore } from 'store-utils';
import { Nullable } from '../../../../../../utilities/ox-common-types/src/typescript-types';
import { Exclusions } from '../types/issues-types';

export const IssuesExclusionsStore = createStore<IssuesExclusionsStoreState>(
  {
    exclusionsCategories: [],
    excludeCategoryId: '',
    excludeCategoryName: '',
    excludeIssueModal: false,
    excludeBulkIssuesModal: false,
    showReportFalsePositiveModal: false,
    showCancelReportFalsePositiveModal: false,
    excludeDefault: false,
    exclusionScope: OxExclusionScope.global,
    excludeOxRuleId: null,
  },
  'Issues Exclusions Store',
);

export interface IssuesExclusionsStoreState {
  exclusionsCategories: Exclusions[];
  excludeCategoryId: string;
  excludeCategoryName: string;
  excludeIssueModal: boolean;
  excludeBulkIssuesModal: boolean;
  excludeDefault: boolean;
  showReportFalsePositiveModal: boolean;
  showCancelReportFalsePositiveModal: boolean;
  exclusionScope: string;
  excludeOxRuleId: Nullable<OxExclusionId>;
}

export default IssuesExclusionsStore;
