import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../active-issues/stores/issues-store';
import { IssueDrawerTabProps } from '../../../../../types/issues-types';
import ScaDependencyGraph from './ScaDependencyGraph';
import { loadDependencyGraph } from './dependency-graph-tab-actions';
import { useOxFlags } from '../../../../../../../feature-flags/actions/feature-flags-actions';

const DependencyGraphTabContainer: FC<
  React.PropsWithChildren<IssueDrawerTabProps>
> = () => {
  const { dependencyGraph, selectedIssue, triggerPackage } =
    useSnapshot(IssuesStore);

  const { sbomMultipleReferencesEnabled } = useOxFlags();

  const onTriggerChange = useCallback(
    (selectedTrigger: string) => {
      if (selectedIssue && selectedTrigger) {
        const [trigger] = selectedTrigger.split(' at ');
        loadDependencyGraph(
          selectedIssue.app.id,
          selectedTrigger,
          selectedIssue.problematicPkg || trigger,
        );
      }
    },
    [selectedIssue],
  );
  useEffect(() => {
    const triggerPackage = sbomMultipleReferencesEnabled
      ? selectedIssue?.scaTriggerPkgs?.[0]
      : {
          scaTriggerPkg: selectedIssue?.scaTriggerPkg || '',
          fileName: '',
        };
    triggerPackage &&
      onTriggerChange(
        `${triggerPackage?.scaTriggerPkg} at ${triggerPackage?.fileName}`,
      );
  }, [onTriggerChange, sbomMultipleReferencesEnabled, selectedIssue]);

  const options = useMemo(
    () =>
      selectedIssue?.scaTriggerPkgs?.map(r => ({
        triggerPackage: r.scaTriggerPkg,
        fileName: r.fileName,
      })) || [],
    [selectedIssue?.scaTriggerPkgs],
  );
  return (
    <>
      {selectedIssue && dependencyGraph && triggerPackage && (
        <ScaDependencyGraph
          appId={selectedIssue.app.id}
          dependencyGraph={dependencyGraph}
          onChangeTriggerPackage={onTriggerChange}
          selectedTrigger={triggerPackage}
          triggerPackages={options}
        />
      )}
    </>
  );
};

export default DependencyGraphTabContainer;
